import { CenterPopup, Image } from 'antd-mobile';
import { BaseTextButton } from 'components/elements';
import { CHECKEVENT, HIDEONEDAY } from 'constants';
import { AGENT } from 'constants/Mobile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { $openBrowser } from 'utils/mobile';
import { getCookieByPrefix } from 'utils/utils';

export function ImageModal({ common, show, data, onCloseOneDay, onClose }) {
    const navigate = useNavigate();

    const fnCloseModal = () => {
        if (onCloseOneDay) onCloseOneDay();
    };

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    const fnMoveToLink = (url) => {
        //const link = 'http://' + url;
        const curLoc = window.location.href;

        if (
            common.agentType === AGENT.ANDROID_APP ||
            common.agentType === AGENT.IOS_APP
        ) {
            if (url.includes(curLoc)) {
                const path = `/${url.split(curLoc)[1]}`;
                navigate(path);
            } else {
                $openBrowser({ link: url });
            }
        } else {
            window.open(url);
        }

        onClose();
    };

    // 스크롤 핸들링
    const preventScroll = (e) => {
        e.preventDefault();
    };

    // 스크롤 핸들링
    useEffect(() => {
        if (show) {
            window.addEventListener('wheel', preventScroll, { passive: false });
            window.addEventListener('touchmove', preventScroll, {
                passive: false,
            });
        } else {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        }

        return () => {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        };
    }, [show]);

    return (
        <CenterPopup visible={show} onMaskClick={fnHandleMaskClick}>
            <div className="popup-list">
                <div className="popup-wrap4">
                    <div onClick={() => fnMoveToLink(data?.img_url)}>
                        <Image
                            src={data?.img_path}
                            alt={`slide ${1}`}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="button-wrap2">
                        <BaseTextButton
                            label={HIDEONEDAY}
                            onClick={fnCloseModal}
                        />
                        <BaseTextButton
                            className={'point-g'}
                            label={CHECKEVENT}
                            onClick={() => fnMoveToLink(data?.img_url)}
                        />
                    </div>
                </div>
            </div>
        </CenterPopup>
    );
}
