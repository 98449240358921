import { BottomPopUp } from 'components/containers';
import { BaseImageButton, BaseRadio, BaseButton } from 'components/elements';
import { CHECK } from 'constants';
import { ADD } from 'constants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_NOTE_EXPEND_GROUP } from 'routes/RouteUrl';

export const WalletCategoryModal = ({
    show,
    defaultData = undefined,
    dataList,
    onClose,
    onOk,
    onClick,
}) => {
    const navigate = useNavigate();
    const [choice, setChoice] = useState({
        idx: -1,
        name: '',
    });

    const fnHandleClick = ({ idx, name }) => {
        // console.log(choice.idx, idx, name)
        // if (choice.idx === idx) setChoice({ idx: -1, name: '' }) //선택해제
        // else setChoice({ idx, name });
        setChoice({ idx, name });
    };

    const fnHandleClose = () => {
        if (onClose) onClose();
    };

    // 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
    // const fnHandleOk = () => {
    //   if (onOk) onOk(choice) ;
    // }

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    useEffect(() => {
        if (defaultData) fnHandleClick(defaultData);
    }, [defaultData]);

    return (
        <BottomPopUp
            show={show}
            hasBottom={false}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-bottom">
                <div className="popup-wrap wallet-category-modal">
                    <div className="popup-head popup-close">
                        <strong>분류를 선택해 주세요.</strong>
                        <BaseImageButton
                            imageSrc={'/assets/images/icon-close.svg'}
                            altText={'닫기'}
                            onClick={fnHandleClose}
                        />
                    </div>
                    <div className="popup-content">
                        <ul className="pt-16 pd-20">
                            <li className="item-wrap">
                                {dataList?.map((item, idx) => (
                                    <div
                                        key={`asset_idx_${idx}`}
                                        className="item align center vm"
                                        onClick={() => {
                                            onOk({
                                                idx: item.idx,
                                                name: item.category_name,
                                            });
                                        }}
                                    >
                                        <BaseRadio
                                            name="check-use-cate"
                                            id={`check-use-cate-${idx}`}
                                            className="check"
                                            defaultChecked={
                                                choice?.idx === item.idx
                                                    ? true
                                                    : false
                                            }
                                            value={item.category_name}
                                        />
                                    </div>
                                ))}
                                <div className="item-add align center vm">
                                    <input
                                        type="radio"
                                        name="check-use"
                                        className="check"
                                        id="check-use"
                                    />
                                    <label htmlFor="check-use">
                                        <BaseImageButton
                                            label={ADD}
                                            imageSrc={
                                                '/assets/images/icon-tag-plus-green.svg'
                                            }
                                            onClick={() =>
                                                navigate(
                                                    URL_NOTE_EXPEND_GROUP,
                                                    {
                                                        state: {
                                                            from: window
                                                                .location
                                                                .pathname,
                                                        },
                                                    },
                                                )
                                            }
                                        />
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제 */}
                    {/* <div className="popup-button-wrap">
              <BaseButton label={CHECK} onClick={fnHandleOk} />
            </div> */}
                </div>
            </div>
        </BottomPopUp>
    );
};
