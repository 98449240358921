import { NetworkError } from 'pages/common/NetworkError';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    URL_COMMUNITY,
    URL_HOME,
    URL_JOIN,
    URL_LOGIN,
    URL_MORE_ACCOUNT,
    URL_MORE_SERVICE_INFO,
} from 'routes/RouteUrl';

export function BaseBody({ header, footer, children }) {
    const [onLine, setOnLine] = useState(true);
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        // 오프라인 확인
        const onLine = window.navigator.onLine;
        setOnLine(onLine);
    }, []);

    const style = (() => {
        if (pathname.includes('/note')) {
            return {
                position: 'absolute',
                transform: 'translateX(-1px)',
            };
        }

        if (
            pathname === URL_LOGIN ||
            pathname === URL_MORE_ACCOUNT ||
            pathname === URL_MORE_SERVICE_INFO
        ) {
            return {
                height: '100vh',
            };
        }

        if (pathname === URL_JOIN) {
            return {
                // height: '100vh',
            };
        }

        if (pathname === URL_HOME || pathname === URL_COMMUNITY) {
            return {
                marginTop: '47px',
            };
        }

        return {};
    })();

    return (
        <>
            {onLine ? (
                <main
                    style={style}
                    className={`layout-body ${!header && 'no-header'} ${!footer && 'no-footer'}`}
                >
                    <div className={'layout-scroll-body'}>{children}</div>
                </main>
            ) : (
                <NetworkError />
            )}
        </>
    );
}
