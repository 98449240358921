import { BaseLink } from 'components/elements';
// import { AdvertisementArea } from './AdvertisementArea';

export const SearchBottomArea = ({
    header,
    content,
    footer,
    onClick,
    memberPlan,
}) => {
    return (
        <>
            <div className="bottom-fixed search">
                {/* { !memberPlan?.planYn &&
          <AdvertisementArea className={'h92'} />
        } */}
                <div className="bottom-faq">
                    <p className="title pb-2">{header}</p>
                    <p className="content">{content}</p>
                    <BaseLink to={onClick} className="point-g">
                        {footer}
                    </BaseLink>
                </div>
            </div>
        </>
    );
};
