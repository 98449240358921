import { useEffect, useState } from 'react';
import { BaseButton, BaseTextButton } from 'components/elements';
import { AreaPickerModal, Loading, LocationCheck } from 'components/containers';
import MyAreaMap from 'components/map/MyAreaMap';
import {
    PLEASECHECKMYAREA,
    SOTONGINAREA,
    UPDATEAREA,
    NEXT,
    SELECTAREA,
    JOIN,
} from 'constants';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import { postMyAreaAPI } from 'api/member';
import { ACCESS_TYPE, AGENT } from 'constants/Mobile';
import { useAccessPermission } from 'hooks/useAccessPermission';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useSelector } from 'react-redux';
import { MobileInterface } from 'pages/common';

export const JoinSettingMyArea = ({
    onMoveStep,
    userIdx,
    showAccess,
    common,
}) => {
    const { checkAccess, requestAccess, checkAccessIOS } =
        useAccessPermission();
    const AGENT_TYPE = common.agentType;

    const location = useSelector((state) => state.common.get('location'));

    const [locationTrue, setLocationTrue] = useState(true); // 위치 정보가 있는지 확인하는 변수
    const [selectedArea, setSelectedArea] = useState({
        sido: null,
        gugun: null,
        code: null,
    });
    const [openModal, setOpenModal] = useState({
        openAreaPickerModal: false,
    });
    const [isShow, setShow] = useState({
        loading: false,
    });
    const [errorData, setErrorData] = useState(null);
    const { ErrorModal } = useErrorHandler({ errorData });

    // 로딩 토글
    const fnToggleLoading = (value) => {
        setShow((prev) => ({ ...prev, loading: value }));
    };

    const fnCloseAreaPickerModal = () => {
        setOpenModal((prev) => ({ ...prev, openAreaPickerModal: false }));
    };

    const fnOpenAreaPickerModal = () => {
        setOpenModal((prev) => ({ ...prev, openAreaPickerModal: true }));
    };

    const fnCheckAccess = async () => {
        // 제인이 생각에는 해당 로직이 있어야 하는게 맞다고 함 나중에 고려해보삼...
        if (AGENT_TYPE === AGENT.ANDROID_APP) {
            const isAndroid = checkAccess({ type: ACCESS_TYPE?.LOCATION });
            if (isAndroid) {
                // 접근권한 있는 경우
                requestAccess({ type: ACCESS_TYPE?.LOCATION });
            } else {
                // 접근권한 없는 경우
                //fnOpenAccessModal()
                showAccess(
                    (data) => {
                        //권한 설정 후 success
                    },
                    () => {
                        //error
                    },
                    () => {
                        //close
                    },
                    ACCESS_TYPE?.LOCATION,
                );
            }
        } else if (AGENT_TYPE === AGENT.IOS_APP) {
            try {
                const res = await checkAccessIOS({
                    type: ACCESS_TYPE.LOCATION,
                });
                if (res) {
                    // 접근권한 있는 경우 - 지역 설정 화면 랜딩
                    requestAccess({ type: ACCESS_TYPE?.LOCATION });
                } else {
                    // 접근권한 없는 경우
                    showAccess(
                        (data) => {
                            //success
                        },
                        () => {
                            //error
                        },
                        () => {
                            //close
                        },
                        ACCESS_TYPE.LOCATION,
                    );
                }
            } catch (err) {
                console.error(err);
            }
        }
    };

    // 위치 정보 없으면 실행하는 함수
    const fnHandleHasLocation = () => {
        setLocationTrue(false);
    };

    const fnSetSelectedArea = (value) => {
        setSelectedArea((prev) => ({
            ...prev,
            sido: value?.sido,
            gugun: value?.gugun,
            code: value?.code,
        }));
    };

    const fnPostMyArea = async (area) => {
        const data = {
            user_idx: '',
            area_code: '',
        };
        if (area?.code) {
            setSelectedArea({
                sido: area.sido,
                gugun: area.gugun,
                code: area.code,
            });
            setSelectedArea((prev) => ({
                sido: area.sido,
                gugun: area.gugun,
                code: area.code,
            }));

            setOpenModal((prev) => ({
                ...prev,
                openAreaPickerModal: false,
            }));

            data.user_idx = userIdx;
            data.area_code = area.code;
        } else {
            data.user_idx = userIdx;
            data.area_code = selectedArea.code;
        }

        fnToggleLoading(true);

        try {
            const res = await postMyAreaAPI({ data });
            const { responseCode } = res?.data;

            if (responseCode === 200) {
                onMoveStep({
                    area: `${area.sido ? area.sido : selectedArea.sido} ${area.gugun ? area.gugun : selectedArea.gugun}`,
                });
            }
        } catch (err) {
            console.error(err);
            setErrorData(err);
        } finally {
            fnToggleLoading(false);
        }
    };

    useEffect(() => {
        if (common.mobile) fnCheckAccess();
    }, []);

    useEffect(() => {
        if (location?.latitude && location?.longitude) {
            // alert("[redux] "+location?.latitude + location?.longitude)
            setLocationTrue(true);
        }
    }, [location]);

    return (
        <>
            <BaseLayout header={false} footer={false}>
                <BasePageHeader title={JOIN} pageType={'join'} />
                <div className="wrap wrap-join">
                    <div className="member">
                        <div className="join">
                            {locationTrue ? (
                                <>
                                    <div className="step-4">
                                        <h2 className="title-l">
                                            {PLEASECHECKMYAREA}
                                        </h2>
                                        <div className="reference">
                                            <p className="reference-s">
                                                {SOTONGINAREA}
                                            </p>
                                        </div>
                                        <div className="map-wrap">
                                            {selectedArea?.sido && (
                                                <div className="location">
                                                    <strong>
                                                        {selectedArea.sido}{' '}
                                                        {selectedArea.gugun}
                                                    </strong>
                                                </div>
                                            )}
                                            <div className="map-box">
                                                <MyAreaMap
                                                    getArea={fnSetSelectedArea}
                                                    onFalse={
                                                        fnHandleHasLocation
                                                    }
                                                    common={common}
                                                />
                                                <BaseTextButton
                                                    label={UPDATEAREA}
                                                    className={
                                                        'location-button'
                                                    }
                                                    onClick={
                                                        fnOpenAreaPickerModal
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <LocationCheck />
                            )}
                        </div>
                    </div>
                </div>
                <div className="button-wrap">
                    {locationTrue ? (
                        <BaseButton label={NEXT} onClick={fnPostMyArea} />
                    ) : (
                        <BaseButton
                            label={SELECTAREA}
                            className="normal"
                            onClick={fnOpenAreaPickerModal}
                        />
                    )}
                </div>

                {/* 에러 */}
                {ErrorModal}
            </BaseLayout>

            {/* 로딩바 */}
            <Loading loading={isShow?.loading} />

            {/* 지역 선택 모달 */}
            <AreaPickerModal
                show={openModal.openAreaPickerModal}
                initArea={selectedArea}
                onOk={fnPostMyArea}
                onClose={fnCloseAreaPickerModal}
            />

            {/* 모바일 통신 - 위치 */}
            <MobileInterface />
        </>
    );
};
