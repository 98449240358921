import { getTodayListAPI } from 'api/home';
import { IconArrowLeft, IconArrowRight } from 'components/assets/icon';
import { WALLET } from 'constants';
import { DIARY } from 'constants';
import { DIARYEMPTY } from 'constants';
import { WALLETEMPTY } from 'constants';
import { useAuth } from 'hooks/AuthContext';
import dayjs from 'dayjs';
import { TodayDiaryListItem } from 'pages/diary/TodayDiaryListItem';
import { TodayWalletListItem } from 'pages/wallet/TodayWalletListItem';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { URL_DIARY, URL_WALLET } from 'routes/RouteUrl';
import { convertStr } from 'utils/utils';

export function HandBookDetail() {
    const { user } = useAuth();
    const userIdx = user?.user_idx || -1; // 로그인/회원가입 유도

    const MAX_WALLET_CNT = 3;
    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(
        dayjs().format('YYYYMMDD'),
    );
    const [todayList, setTodayList] = useState({
        diaryList: [],
        walletList: [],
    });
    const [walletTotalCnt, setWalletTotalCnt] = useState(0);

    const prevDate = () => {
        const today = dayjs(selectedDate).startOf('day');
        const nextDate = today.add(-1, 'day');
        setSelectedDate(nextDate.format('YYYYMMDD'));
    };
    const nextDate = () => {
        const today = dayjs(selectedDate).startOf('day');
        const nextDate = today.add(1, 'day');
        setSelectedDate(nextDate.format('YYYYMMDD'));
    };

    const fnDiaryClick = () => {
        navigate(URL_DIARY, {
            state: { pickDate: convertStr(selectedDate, '.') },
        });
    };
    const fnWalletClick = () => {
        navigate(URL_WALLET, {
            state: { pickDate: convertStr(selectedDate, '.') },
        });
    };

    const getTodayDiaryWallet = async () => {
        const params = {
            user_idx: userIdx,
            search_date: convertStr(selectedDate, '.'),
        };

        try {
            const res = await getTodayListAPI({ params });
            const { data_info } = res?.data;

            setTodayList(data_info);
            setWalletTotalCnt(data_info?.total_cnt);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getTodayDiaryWallet();
    }, [selectedDate]);

    return (
        <>
            <div
                className="wrap"
                style={{
                    WebkitOverflowScrolling: 'touch',
                    paddingBottom: '75px',
                }}
            >
                <div className="diary hand-book-detail">
                    <div className="diary-wrap hand-book-detail-date">
                        <div className="calendar-inline-wrap">
                            <IconArrowLeft onClick={prevDate} />
                            <h2 className="title">
                                {selectedDate.substring(0, 4)}년{' '}
                                {selectedDate.substring(4, 6)}월{' '}
                                {selectedDate.substring(6, 8)}일
                            </h2>
                            <IconArrowRight onClick={nextDate} />
                        </div>
                    </div>
                    <div className="hand-book-detail-con">
                        <div className="diary-wrap content pd-lr-30">
                            <div className="log-wrap pt-16 mb-32">
                                <div className="title-wrap align both vm">
                                    <h2>{DIARY}</h2>
                                    <button
                                        className="btn-arrow-right"
                                        onClick={fnDiaryClick}
                                    ></button>
                                </div>
                                {todayList?.diaryList?.length > 0 ? (
                                    <TodayDiaryListItem
                                        dataList={todayList?.diaryList}
                                        onClick={fnDiaryClick}
                                        divClassName="comn-box log-box"
                                    />
                                ) : (
                                    <div
                                        className="comn-box log-box"
                                        onClick={fnDiaryClick}
                                    >
                                        <p className="empty-con">
                                            {DIARYEMPTY}
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className="account-wrap">
                                <div className="title-wrap align both vm">
                                    <h2>{WALLET}</h2>
                                    <button
                                        className="btn-arrow-right"
                                        onClick={fnWalletClick}
                                    ></button>
                                </div>
                                <div className="comn-box account-box">
                                    {todayList?.walletList?.length > 0 ? (
                                        <ul>
                                            <TodayWalletListItem
                                                dataList={todayList?.walletList}
                                                onClick={fnWalletClick}
                                            />
                                        </ul>
                                    ) : (
                                        <p
                                            className="empty-con"
                                            onClick={fnWalletClick}
                                        >
                                            {WALLETEMPTY}
                                        </p>
                                    )}
                                    {walletTotalCnt > MAX_WALLET_CNT && (
                                        <p className="info">
                                            <span>
                                                외{' '}
                                                {walletTotalCnt -
                                                    MAX_WALLET_CNT}
                                                건
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
