import React, { useState } from 'react';
import { BaseImageButton, BaseInput } from 'components/elements';

const areEqual = (prev, next) => {
    return JSON.stringify(prev) === JSON.stringify(next);
};

const HASHTAG_MAX_CNT = 10;
export const HashTags = React.memo(({ data = undefined, onEdit, onFocus }) => {
    const [editedTag, setEditedTag] = useState(
        data && data.length !== 0
            ? [...data?.map((item) => ({ label: item, already: true }))]
            : [{ label: '', already: false }],
    );

    const fnAddTag = () => {
        if (editedTag.length < HASHTAG_MAX_CNT)
            setEditedTag([...editedTag, { label: '', already: false }]);
    };

    const fnEditTag = ({ value, idx }) => {
        const newValue = `#${value}`;
        const newTags = editedTag;
        newTags[idx] = { ...newTags[idx], label: newValue.replace(/^#+/, '#') };
        setEditedTag([...newTags]);
        if (onEdit) onEdit(newTags.map((item) => item.label));
        // const newTags = editedTag.map((item, index) => {
        //     if (index === idx) return `#${value}`;
        //     return item
        // })
        // newTags[idx] = newTags[idx].replace(/^#+/, '#')
        // setEditedTag(newTags)
        // if (onEdit) onEdit(newTags)
    };

    return (
        // <ul>
        <>
            {editedTag?.map((item, idx) => (
                <div key={`tags_idx_${idx}`} className="input-wrapper">
                    <span className="size-span">{item.label}</span>
                    <BaseInput
                        onFocus={onFocus}
                        placeholder="#태그입력"
                        className={`input-tag ${item.already ? 'active' : ''}`}
                        //disabled={item?.disabled || false}
                        maxLength={10}
                        value={item.label}
                        onChange={(v) => fnEditTag({ value: v, idx })}
                    />
                    {/* <input
                        onInput={updateChange}
                        placeholder="#태그입력"
                        maxLength={10}
                        type="text"
                        className="example-input"
                        value={value}
                        onChange={updateChange}
                        /> */}
                </div>
            ))}
            <div className="tag-add-wrap">
                <BaseImageButton
                    imageSrc={'/assets/images/add-2.svg'}
                    onClick={fnAddTag}
                />
            </div>
        </>
        // </ul>
    );
}, areEqual);

// export const HashTags = React.memo(({ data=undefined, onEdit }) => {
//     const newData = data?.map(item => ({label: item, disabled: false}))
//   const [editedTag, setEditedTag] = useState(data && data.length !== 0 ? [...data] : ['']);

//   const fnAddTag = () => {
//       setEditedTag([...editedTag, ''])
//   }

//   const fnEditTag = ({ value, idx }) => {
//       const newTags = editedTag.map((item, index) => {
//           if (index === idx) return `#${value}`;
//           return item
//       })
//       newTags[idx] = newTags[idx].replace(/^#+/, '#')
//       setEditedTag(newTags)
//       if (onEdit) onEdit(newTags)
//   }

//     return (
//         // <ul>
//         <>
//             {
//                 editedTag?.map((item, idx) =>
//                     <div key={`tags_idx_${idx}`} className="input-wrapper">
//                         <span className="size-span">{item}</span>
//                         <BaseInput
//                             placeholder='#태그입력' className="input-tag"
//                             disabled={item?.disabled || false}
//                             maxLength={11} value={item}
//                             onChange={(v) => fnEditTag({value: v, idx})}
//                         />
//                         {/* <input
//                         onInput={updateChange}
//                         placeholder="#태그입력"
//                         maxLength={10}
//                         type="text"
//                         className="example-input"
//                         value={value}
//                         onChange={updateChange}
//                         /> */}
//                     </div>
//                 )
//             }
//             <div className="tag-add-wrap">
//                 <BaseImageButton imageSrc={'/assets/images/add-2.svg'} onClick={fnAddTag}/>
//             </div>
//         </>
//         // </ul>
//     )
// }, areEqual);
