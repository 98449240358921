import { useEffect, useState } from 'react';

export function SelectableSpan({
    id = '',
    children,
    className = '',
    spanClassName = '',
    defaultText = '',
    value,
    onClick,
    onSpanClick,
    active = false,
}) {
    const [text, setText] = useState(defaultText);
    const [conditionalStyle, setConditionalStyle] = useState({});
    const [conditionalStyle2, setConditionalStyle2] = useState({});
    // const styles = {
    //   color: '#5D7068',
    //   //borderBottom: '0.1rem solid #000'
    // };
    // const styles2 = {
    //   borderBottom: '0.1rem solid #000'
    // };

    const style = {
        isValueText: {
            color: '#000',
            fontWeight: 500,
        },
        isValueBottomLine: {
            borderBottom: '0.1rem solid #000',
        },
        isActive: {
            borderBottom: '0.2rem solid #40DF9F',
        },
    };

    const handleClick = () => {
        if (onClick) onClick();
    };

    const handleSpanClick = () => {
        if (onSpanClick) onSpanClick();
    };

    // 선택된값이 기존값이 있을땐 텍스트 색상만 변경되는게 맞음
    useEffect(() => {
        setText(value);
        if (value) {
            setConditionalStyle(style.isValueText);
        } else setConditionalStyle({});
    }, [value]);

    // active(모달열기닫기) 기준으로는 밑줄 색상만 변경되는게 맞음
    useEffect(() => {
        if (active) {
            setConditionalStyle2(style.isActive);
        } else {
            if (value) {
                setConditionalStyle2(style.isValueBottomLine);
            } else {
                setConditionalStyle2({});
            }
        }
    }, [active]);

    return (
        <div
            className={className}
            style={conditionalStyle2}
            onClick={handleClick}
        >
            <span
                className={spanClassName}
                id={id}
                style={conditionalStyle}
                onClick={handleSpanClick}
            >
                {text ? text : defaultText}
            </span>
            {children}
        </div>
    );
}
