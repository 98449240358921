import { IconLock, IconUnLock, chpLogoSrc } from 'components/assets/icon';
import { ProfileImage } from 'components/containers';
import { ALL } from 'constants';
import { newLineStr } from 'utils/utils';

/*
 * 일지, 가계부 에서 공통으로 쓰이는 것
 */
export const useDiaryWalllet = () => {
    const WriteUpdateCnt = ({ data }) => {
        const writerNickname = data?.filter(
            (item) => item.action_type === 0,
        )?.[0]?.user_nickname;
        const updateCntNotMe = data?.filter(
            (item) =>
                item.action_type === 1 &&
                item?.user_nickname !== writerNickname,
        )?.length;
        if (updateCntNotMe > 0) return `외 ${updateCntNotMe}인`;
        else return '';
    };

    const WriteUpdateHistory = ({ dataList }) => {
        const writeList = dataList?.filter((item) => item.action_type === 0);
        const updateList = dataList?.filter((item) => item.action_type === 1);

        return (
            <>
                {writeList?.length > 0 && (
                    <div className="user-writer-wrap">
                        <p className="title">작성자</p>
                        {writeList?.map((item, idx) => (
                            <div
                                key={`w_idx_${idx}`}
                                className="profile-horizental profile-img-24"
                            >
                                <div className="profile-wrap profile-img-24">
                                    <ProfileImage
                                        size={24}
                                        imageSrc={item.profile_img_path}
                                    />
                                    {/* <div className="profile-img-box">
                      <img className="profile cover" src="/assets/images/square-cat.png" alt="프로필 이미지"/>
                    </div> */}
                                </div>
                                <span className="nick-name">
                                    {item.user_nickname}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
                {updateList?.length > 0 && (
                    <div className="user-editer-wrap">
                        <p className="title">수정자</p>
                        <div className="editer-grpup">
                            {updateList?.map((item, idx) => (
                                <div
                                    key={`u_idx_${idx}`}
                                    className="profile-horizental profile-img-24"
                                >
                                    <ProfileImage
                                        size={24}
                                        imageSrc={item.profile_img_path}
                                    />
                                    <span className="nick-name">
                                        {item.user_nickname}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </>
        );
    };

    const NoteAnimalProfile = ({ imageSrc, name }) => {
        let displayImg = imageSrc;
        let displayName = name;

        if (!displayName) {
            // 이름이 없는 경우 '전체', 발바닥 이미지
            displayName = ALL;
            displayImg = chpLogoSrc;
        }

        return (
            <div className="profile-vertical profile-img-40">
                <ProfileImage size={40} imageSrc={displayImg} />
                <div className="writer-info-wrap">
                    <p
                        className="animal-name"
                        dangerouslySetInnerHTML={{
                            __html: newLineStr(displayName, 4),
                        }}
                    ></p>
                </div>
            </div>
        );
    };

    const Lock = ({ lockFg }) => {
        if (lockFg === 1) return <IconLock />;
        return <IconUnLock />;
    };

    const checkMyPetAll = ({ pet_idx, pet_name }) => {
        if (!pet_idx) {
            // 서버에서 오는 pet_idx null인 경우 '전체'임. mypetmodal에서는 전체가 -1임
            return { pet_idx: -1, pet_name: ALL };
        }
        return { pet_idx, pet_name };
    };

    return {
        WriteUpdateCnt,
        WriteUpdateHistory,
        NoteAnimalProfile,
        Lock,
        checkMyPetAll,
    };
};

// <div className="profile-vertical profile-img-40">
//     <ProfileImage size={40} imageSrc={item?.walletHistoryList.filter(item => item.action_type === 0)?.[0]?.profile_img_path}/>
//     <div className="writer-info-wrap">
//         <p className="animal-name">{item?.walletHistoryList.filter(item => item.action_type === 0)?.[0]?.user_nickname}</p>
//     </div>
// </div>
