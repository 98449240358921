import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    URL_HOME,
    URL_JOIN,
    URL_TOKEN,
    URL_NOTICE,
    URL_PUSH_MAIN,
    URL_NOTICE_BOARD_DETAIL,
    URL_COMMUNITY,
    URL_COMMUNITY_SEARCH,
    URL_COMMUNITY_ADD,
    URL_COMMUNITY_DETAIL,
    URL_MYHISTORY,
    URL_NOTE_SEARCH,
    URL_DIARY,
    URL_DIARY_ADD,
    URL_DIARY_MODIFY,
    URL_WALLET,
    URL_WALLET_ANALYSIS,
    URL_WALLET_ADD,
    URL_WALLET_MODIFY,
    URL_NOTE_SETTING,
    URL_NOTE_SETTING_ANIMAL_ADD,
    URL_NOTE_SETTING_ANIMAL_MODIFY,
    URL_NOTE_EXPEND_GROUP,
    URL_NOTE_EXPEND_GROUP_ADD,
    URL_NOTE_EXPEND_GROUP_MODIFY,
    URL_NOTE_SHARE_TAKE,
    URL_NOTE_SHARE_GIVE,
    URL_NOTE_SETTING_ASSET,
    URL_NOTE_SETTING_ASSET_ADD,
    URL_NOTE_SETTING_ASSET_MODIFY,
    URL_NOTE_SETTING_BUDGET,
    URL_NOTE_MANAGE_MEMBER,
    URL_NOTE_INITIAL,
    URL_NOTE_DATA_EXPORT,
    URL_NOTE_DATA_IMPORT,
    URL_MORE,
    URL_MORE_SETTING_ANIMAL,
    URL_MORE_PROFILE,
    URL_MORE_PROFILE_SETTING_NICK,
    URL_MORE_ACCOUNT,
    URL_MORE_SUBSCRIPTION,
    URL_MORE_SUBSCRIPTION_CANCEL,
    URL_MORE_SETTING_AREA,
    URL_MORE_SETTING_NOTIFICATION,
    URL_MORE_FAQ,
    URL_MORE_FAQ_DETAIL,
    URL_MORE_SERVICE_INFO,
    URL_MORE_SERVICE_DETAIL_LOCATION,
    URL_MORE_SERVICE_DETAIL_PERSONAL,
    URL_MORE_SERVICE_DETAIL_USE,
    URL_MORE_RULE_COMMUNITY,
    URL_MORE_COMPANY_INFO,
    URL_MONTHLY,
    URL_MONTHLY_DETAIL,
    URL_POPULAR,
    URL_LOGIN,
    URL_MORE_WITHDRAW,
    URL_MORE_COUPON,
    URL_PROFILE,
    URL_MORE_SERVICE_DETAIL_MARKETING,
    URL_LINK_CHECK,
    // URL_SAMPLE,
    // URL_SAMPLE_KZ,
    // URL_SAMPLE_JEN,
    // URL_SAMPLE_MODAL,
    // URL_SAMPLE_SEARCH,
} from './RouteUrl';

import { AuthLayout } from 'components/auth/AuthLayout';
import RequireAuth from 'components/auth/RequireAuth';

import { Join, JoinGetToken, JoinLogin, JoinTermMarketing } from 'pages/join';

import LinkWebApp from 'pages/common/LinkWebApp';
import { NoMathPage } from 'pages/common';
import { Loading } from 'components/containers';

// import Sample from 'pages/sample/Sample';
// import SampleKZ from 'pages/sample/KkazzongSample';
// import SampleJen from 'pages/sample/SampleJen';
// import SampleModal from 'pages/sample/SampleModal';
// import { SearchBox } from 'pages/sample/SampleSearchBox';

const Home = React.lazy(() => import('pages/home/Home'));
const Monthly = React.lazy(() => import('pages/home/Monthly'));
const MonthlyDetail = React.lazy(() => import('pages/home/MonthlyDetail'));
const Popular = React.lazy(() => import('pages/home/Popular'));

const Notice = React.lazy(() => import('pages/notice/Notice'));
const NoticeBoardDetail = React.lazy(
    () => import('pages/notice/NoticeBoardDetail')
);
const PushMain = React.lazy(() => import('pages/notice/PushMain'));

const Community = React.lazy(() => import('pages/community/Community'));
const CommunitySearch = React.lazy(
    () => import('pages/community/CommunitySearch')
);
const CommunityAdd = React.lazy(() => import('pages/community/CommunityAdd'));
const CommunityDetail = React.lazy(
    () => import('pages/community/CommunityDetail')
);
const MyHistory = React.lazy(() => import('pages/community/MyHistory'));
const Neighbor = React.lazy(() => import('pages/community/Neighbor'));

const Diary = React.lazy(() => import('pages/diary/Diary'));
const DiaryAdd = React.lazy(() => import('pages/diary/DiaryAdd'));
const DiaryModify = React.lazy(() => import('pages/diary/DiaryModify'));

const Wallet = React.lazy(() => import('pages/wallet/Wallet'));
const WalletAddContainer = React.lazy(
    () => import('pages/wallet/WalletAddContainer')
);
const WalletModifyContainer = React.lazy(
    () => import('pages/wallet/WalletModifyContainer')
);
const Analysis = React.lazy(() => import('pages/wallet/Analysis'));

const NoteSearch = React.lazy(() => import('pages/note/NoteSearch'));
const Setting = React.lazy(() => import('pages/note/Setting'));
const AnimalAdd = React.lazy(() => import('pages/note/AnimalAdd'));
const AnimalModify = React.lazy(() => import('pages/note/AnimalModify'));
const ExpendGroup = React.lazy(() => import('pages/note/ExpendGroup'));
const ExpendGroupAdd = React.lazy(() => import('pages/note/ExpendGroupAdd'));
const ExpendGroupModify = React.lazy(
    () => import('pages/note/ExpendGroupModify')
);
const NoteShareTake = React.lazy(() => import('pages/note/NoteShareTake'));
const NoteShareGive = React.lazy(() => import('pages/note/NoteShareGive'));
const SettingBudget = React.lazy(() => import('pages/note/SettingBudget'));
const SettingAsset = React.lazy(() => import('pages/note/SettingAsset'));
const SettingAssetAdd = React.lazy(() => import('pages/note/SettingAssetAdd'));
const SettingAssetModify = React.lazy(
    () => import('pages/note/SettingAssetModify')
);
const ManageMember = React.lazy(() => import('pages/note/ManageMember'));
const NoteInitial = React.lazy(() => import('pages/note/NoteInitial'));
const DataExport = React.lazy(() => import('pages/note/DataExport'));
const DataImport = React.lazy(() => import('pages/note/DataImport'));

const More = React.lazy(() => import('pages/more/More'));
const ProfileSetting = React.lazy(() => import('pages/more/ProfileSetting'));
const ProfileSettingNick = React.lazy(
    () => import('pages/more/ProfileSettingNick')
);
const Account = React.lazy(() => import('pages/more/Account'));
const Withdraw = React.lazy(() => import('pages/more/Withdraw'));
const Subscription = React.lazy(() => import('pages/more/Subscription'));
const SubscriptionCancel = React.lazy(
    () => import('pages/more/SubscriptionCancel')
);
const SettingArea = React.lazy(() => import('pages/more/SettingArea'));
const SettingAnimal = React.lazy(() => import('pages/more/SettingAnimal'));
const SettingNotification = React.lazy(
    () => import('pages/more/SettingNotification')
);
const Faq = React.lazy(() => import('pages/more/Faq'));
const FaqDetail = React.lazy(() => import('pages/more/FaqDetail'));
const ServiceInfo = React.lazy(() => import('pages/more/ServiceInfo'));
const ServiceInfoDetailLocation = React.lazy(
    () => import('pages/more/ServiceInfoDetailLocation')
);
const ServiceInfoDetailPersonal = React.lazy(
    () => import('pages/more/ServiceInfoDetailPersonal')
);
const ServiceInfoDetailUse = React.lazy(
    () => import('pages/more/ServiceInfoDetailUse')
);
const RuleGuideCommunity = React.lazy(
    () => import('pages/more/RuleGuideCommunity')
);
const CompanyInfo = React.lazy(() => import('pages/more/CompanyInfo'));
const Coupon = React.lazy(() => import('pages/more/Coupon'));

const RouterConfig = ({ common, showAccess }) => {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                {/* sample page */}
                {/* <Route path={URL_SAMPLE} element={<Sample common={common} />} />
        <Route path={URL_SAMPLE_KZ} element={<SampleKZ common={common} />} />
        <Route path={URL_SAMPLE_JEN} element={<SampleJen common={common} />} />
        <Route path={URL_SAMPLE_MODAL} element={<SampleModal common={common} />} />
        <Route path={URL_SAMPLE_SEARCH} element={<SearchBox common={common} />} /> */}

                {/* <Route path="/" element={<Navigate replace to={`${URL_HOME}`} />} /> */}

                <Route
                    path={URL_LINK_CHECK}
                    element={<LinkWebApp common={common} />}
                />

                <Route path={URL_HOME} element={<Home common={common} />} />
                <Route
                    path={URL_POPULAR}
                    element={<Popular common={common} />}
                />
                <Route
                    path={URL_MONTHLY}
                    element={<Monthly common={common} />}
                />
                <Route
                    path={URL_MONTHLY_DETAIL}
                    element={<MonthlyDetail common={common} />}
                />

                <Route
                    path={URL_LOGIN}
                    element={<JoinLogin common={common} />}
                />
                <Route
                    path={URL_TOKEN}
                    element={<JoinGetToken common={common} />}
                />

                <Route
                    path={URL_JOIN}
                    element={<Join common={common} showAccess={showAccess} />}
                />

                <Route
                    path={URL_COMMUNITY}
                    element={<Community common={common} />}
                />
                <Route
                    path={URL_COMMUNITY_DETAIL}
                    element={<CommunityDetail common={common} />}
                />

                <Route path={URL_NOTICE} element={<Notice common={common} />} />
                <Route
                    path={URL_NOTICE_BOARD_DETAIL}
                    element={<NoticeBoardDetail common={common} />}
                />

                <Route path={URL_MORE_FAQ} element={<Faq common={common} />} />
                <Route
                    path={URL_MORE_FAQ_DETAIL}
                    element={<FaqDetail common={common} />}
                />

                <Route
                    path={URL_MORE_RULE_COMMUNITY}
                    element={<RuleGuideCommunity common={common} />}
                />
                <Route
                    path={URL_MORE_COMPANY_INFO}
                    element={<CompanyInfo common={common} />}
                />
                <Route
                    path={URL_MORE_SERVICE_INFO}
                    element={<ServiceInfo common={common} />}
                />
                <Route
                    path={URL_MORE_SERVICE_DETAIL_LOCATION}
                    element={<ServiceInfoDetailLocation common={common} />}
                />
                <Route
                    path={URL_MORE_SERVICE_DETAIL_PERSONAL}
                    element={<ServiceInfoDetailPersonal common={common} />}
                />
                <Route
                    path={URL_MORE_SERVICE_DETAIL_USE}
                    element={<ServiceInfoDetailUse common={common} />}
                />
                <Route
                    path={URL_MORE_SERVICE_DETAIL_MARKETING}
                    element={<JoinTermMarketing common={common} />}
                />

                <Route
                    path="/"
                    element={
                        <RequireAuth common={common}>
                            <AuthLayout common={common} />
                        </RequireAuth>
                    }
                >
                    <Route
                        path={URL_PUSH_MAIN}
                        element={<PushMain common={common} />}
                    />

                    <Route
                        path={URL_COMMUNITY_ADD}
                        element={
                            <CommunityAdd
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />
                    <Route
                        path={URL_COMMUNITY_SEARCH}
                        element={<CommunitySearch common={common} />}
                    />

                    <Route
                        path={URL_MYHISTORY}
                        element={<MyHistory common={common} />}
                    />
                    <Route
                        path={URL_PROFILE}
                        element={<Neighbor common={common} />}
                    />

                    <Route
                        path={URL_NOTE_SEARCH}
                        element={<NoteSearch common={common} />}
                    />

                    <Route
                        path={URL_DIARY}
                        element={<Diary common={common} />}
                    />
                    <Route
                        path={URL_DIARY_ADD}
                        element={
                            <DiaryAdd common={common} showAccess={showAccess} />
                        }
                    />
                    <Route
                        path={URL_DIARY_MODIFY}
                        element={
                            <DiaryModify
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />

                    <Route
                        path={URL_WALLET}
                        element={<Wallet common={common} />}
                    />
                    <Route
                        path={URL_WALLET_ADD}
                        element={<WalletAddContainer common={common} />}
                    />
                    <Route
                        path={URL_WALLET_MODIFY}
                        element={<WalletModifyContainer common={common} />}
                    />
                    <Route
                        path={URL_WALLET_ANALYSIS}
                        element={<Analysis common={common} />}
                    />
                    <Route
                        path={URL_NOTE_EXPEND_GROUP}
                        element={<ExpendGroup common={common} />}
                    />
                    <Route
                        path={URL_NOTE_EXPEND_GROUP_ADD}
                        element={<ExpendGroupAdd common={common} />}
                    />
                    <Route
                        path={URL_NOTE_EXPEND_GROUP_MODIFY}
                        element={<ExpendGroupModify common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SHARE_TAKE}
                        element={<NoteShareTake common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SHARE_GIVE}
                        element={<NoteShareGive common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SETTING_ASSET}
                        element={<SettingAsset common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SETTING_ASSET_ADD}
                        element={<SettingAssetAdd common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SETTING_ASSET_MODIFY}
                        element={<SettingAssetModify common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SETTING}
                        element={<Setting common={common} />}
                    />
                    <Route
                        path={URL_NOTE_SETTING_ANIMAL_ADD}
                        element={
                            <AnimalAdd
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />
                    <Route
                        path={URL_NOTE_SETTING_ANIMAL_MODIFY}
                        element={
                            <AnimalModify
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />
                    <Route
                        path={URL_NOTE_SETTING_BUDGET}
                        element={<SettingBudget common={common} />}
                    />
                    <Route
                        path={URL_NOTE_MANAGE_MEMBER}
                        element={<ManageMember common={common} />}
                    />
                    <Route
                        path={URL_NOTE_INITIAL}
                        element={<NoteInitial common={common} />}
                    />
                    <Route
                        path={URL_NOTE_DATA_EXPORT}
                        element={<DataExport common={common} />}
                    />
                    <Route
                        path={URL_NOTE_DATA_IMPORT}
                        element={<DataImport common={common} />}
                    />

                    <Route path={URL_MORE} element={<More common={common} />} />
                    <Route
                        path={URL_MORE_PROFILE}
                        element={
                            <ProfileSetting
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />
                    <Route
                        path={URL_MORE_PROFILE_SETTING_NICK}
                        element={<ProfileSettingNick common={common} />}
                    />
                    <Route
                        path={URL_MORE_SETTING_AREA}
                        element={
                            <SettingArea
                                common={common}
                                showAccess={showAccess}
                            />
                        }
                    />
                    <Route
                        path={URL_MORE_SETTING_ANIMAL}
                        element={<SettingAnimal common={common} />}
                    />
                    <Route
                        path={URL_MORE_ACCOUNT}
                        element={<Account common={common} />}
                    />
                    <Route
                        path={URL_MORE_WITHDRAW}
                        element={<Withdraw common={common} />}
                    />
                    <Route
                        path={URL_MORE_SUBSCRIPTION}
                        element={<Subscription common={common} />}
                    />
                    <Route
                        path={URL_MORE_SUBSCRIPTION_CANCEL}
                        element={<SubscriptionCancel common={common} />}
                    />
                    <Route
                        path={URL_MORE_SETTING_NOTIFICATION}
                        element={<SettingNotification common={common} />}
                    />
                    <Route
                        path={URL_MORE_COUPON}
                        element={<Coupon common={common} />}
                    />
                </Route>

                <Route path="*" element={<NoMathPage />} />
            </Routes>
        </Suspense>
    );
};

export default RouterConfig;
