export const TYPES = {
    // 멤버 플랜
    SET_MEMBER_PLAN: 'SET_MEMBER_PLAN',

    // 설정
    SET_ACCOUNT_MANAGE: 'SET_ACCOUNT_MANAGE',
    SET_SUBSCRIBE_MANAGE: 'SET_SUBSCRIBE_MANAGE',
    SET_ALARM_SETTING_MANAGE: 'SET_ALARM_SETTING_MANAGE',

    // 고객지원
    // 앱 응원하기는 필요 없을 듯? (구글 플레이 스토어로 이동됨)
    SET_FAQ_LIST: 'SET_FAQ_LIST',

    // 기타
    SET_ANNOUNCEMENT_LIST: 'SET_ANNOUNCEMENT_LIST',
    SET_COUPON_MANAGE: 'SET_COUPON_MANAGE',
    SET_SERVICE_INFORMATION_LIST: 'SET_SERVICE_INFORMATION_LIST',
};

const initState = {
    moreList: {
        // 멤버 플랜
        isMemberPlan: {},
        // 1. 설정
        // 계정 관리
        accountManage: {},
        // 구독 관리
        subscribeManage: {},
        // 알림 설정
        alarmSettingManage: {},

        // 2. 고객지원
        faqList: [],

        // 3. 기타
        announcementList: [],
        couponManage: {},
        serviceInformationList: [],
    },
};

export default function MoreReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_MEMBER_PLAN:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    isMemberPlan: action.payload,
                }
            }

        case TYPES.SET_ACCOUNT_MANAGE:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    accountManage: action.payload,
                },
            };

        case TYPES.SET_SUBSCRIBE_MANAGE:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    subscribeManage: action.payload,
                },
            };

        case TYPES.SET_ALARM_SETTING_MANAGE:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    alarmSettingManage: action.payload,
                },
            };

        case TYPES.SET_FAQ_LIST:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    faqList: action.payload,
                },
            };

        case TYPES.SET_ANNOUNCEMENT_LIST:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    announcementList: action.payload,
                },
            };

        case TYPES.SET_COUPON_MANAGE:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    couponManage: action.payload,
                },
            };

        case TYPES.SET_SERVICE_INFORMATION_LIST:
            return {
                ...state,
                moreList: {
                    ...state.moreList,
                    serviceInformationList: action.payload,
                },
            };

        default:
            return state;
    }
}

export const actions = {
    setMemberPlan: (data) => ({ type: TYPES.SET_MEMBER_PLAN, payload: data }),
    setAccountManage: (data) => ({ type: TYPES.SET_ACCOUNT_MANAGE, payload: data }),
    setSubscribeManage: (data) => ({ type: TYPES.SET_SUBSCRIBE_MANAGE, payload: data }),
    setAlarmSettingManage: (data) => ({ type: TYPES.SET_ALARM_SETTING_MANAGE, payload: data }),
    setFaqList: (data) => ({ type: TYPES.SET_FAQ_LIST, payload: data }),
    setAnnouncementList: (data) => ({ type: TYPES.SET_ANNOUNCEMENT_LIST, payload: data }),
    setCouponManage: (data) => ({ type: TYPES.SET_COUPON_MANAGE, payload: data }),
    setServiceInformationList: (data) => ({ type: TYPES.SET_SERVICE_INFORMATION_LIST, payload: data })
}