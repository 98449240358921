import { postToken } from 'api/common';
import { Loading } from 'components/containers';
import { BaseLayout } from 'components/layouts';
import { AGENT } from 'constants/Mobile';
import { useAuth } from 'hooks/AuthContext';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL_HOME, URL_JOIN } from 'routes/RouteUrl';
import { $get, $getIOS } from 'utils/mobile';

// Redux
import { actions } from 'store/push-reducer';
import { useDispatch } from 'react-redux';

export const JoinGetToken = ({ common }) => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('login_token') || '';
    const idx = urlParams.get('user_idx') || ''; // 회원idx
    // 1: 회원가입, 2: 로그인, 3: 약관동의, 4: 닉네임 설정, 5: 선호동물, 6: 내 지역 선택
    const fg = urlParams.get('login_fg') || '';
    const AGENT_TYPE = common.agentType;
    const [isShow, setShow] = useState({
        loading: false,
    });
    const [pushToken, setPushToken] = useState(null);

    const [errorData, setErrorData] = useState(null);
    const { ErrorModal } = useErrorHandler({ errorData });

    const dispatch = useDispatch();

    const fnToggleLoading = (value) => {
        setShow((prev) => ({ ...prev, loading: value }));
    };

    // console.log(token);
    // console.log(idx);
    // console.log(fg);

    const doLogin = () => {
        // token : 유저 토큰
        login({ token: token, user_idx: idx });
        navigate(URL_HOME, { replace: true });
    };

    const fnPushToken = async () => {
        // 모바일 푸시 토큰 등록
        const os_type =
            AGENT_TYPE === AGENT.ANDROID_APP || AGENT_TYPE === AGENT.ANDROID_WEB
                ? 1
                : 2;
        const data = {
            user_idx: idx,
            os_type,
            push_token: pushToken,
        };

        fnToggleLoading(true);

        try {
            const res = postToken({ data });
            // const { responseCode } = res.data;
            // if (responseCode === 200) doLogin()
        } catch (err) {
            console.error(err);
            setErrorData(err);
        } finally {
            if (fg === '2') doLogin(); // auth 로그인
            fnToggleLoading(false);
        }
    };

    useEffect(() => {
        // 회원가입 or 약관동의
        if (fg === '1' || fg === '3') {
            if (common.mobile) {
                if (AGENT_TYPE === AGENT.ANDROID_APP) {
                    // 앱
                    const token = $get('token');
                    if (token) {
                        // 토큰이 있는 경우 세팅
                        setPushToken(token);
                    }
                } else if (AGENT_TYPE === AGENT.IOS_APP) {
                    $getIOS('token', (data) => {
                        if (data) {
                            // 토큰이 있는 경우 세팅
                            setPushToken(data);
                        }
                    });
                }
            }
            setTimeout(() => {
                navigate(URL_JOIN, {
                    state: { step: fg, loginCmp: true, token: token, idx: idx },
                    replace: true,
                });
            }, 1000);

            // 로그인
        } else if (fg === '2') {
            if (common.mobile) {
                if (AGENT_TYPE === AGENT.ANDROID_APP) {
                    // 앱
                    const token = $get('token');
                    if (token) {
                        // 토큰이 있는 경우 세팅
                        setPushToken(token);
                    }
                } else if (AGENT_TYPE === AGENT.IOS_APP) {
                    $getIOS('token', (data) => {
                        if (data) {
                            // 토큰이 있는 경우 세팅
                            setPushToken(data);
                        } else {
                            // token 이 없으면 그냥 로그인
                            doLogin();
                        }
                    });
                } else if (
                    AGENT_TYPE === AGENT.ANDROID_WEB ||
                    AGENT_TYPE === AGENT.IOS_WEB
                ) {
                    doLogin();
                }
            } else {
                // pc
                doLogin();
            }

            // login({ token: token, user_idx: idx });
            // navigate(URL_HOME, { replace: true });
            // setTimeout(() => {
            //     navigate(URL_JOIN, {
            //         state: { step: fg, token: token, idx: idx },
            //         replace: true,
            //     });
            // }, 1000);

            // 닉네임 설정, 선호동물, 내 지역 선택
        } else {
            if (common.mobile) {
                if (AGENT_TYPE === AGENT.ANDROID_APP) {
                    // 앱
                    const token = $get('token');
                    if (token) {
                        // 토큰이 있는 경우 세팅
                        setPushToken(token);
                    }
                } else if (AGENT_TYPE === AGENT.IOS_APP) {
                    $getIOS('token', (data) => {
                        if (data) {
                            // 토큰이 있는 경우 세팅
                            setPushToken(data);
                        }
                    });
                }
            }

            setTimeout(() => {
                navigate(URL_JOIN, {
                    state: { step: fg, token: token, idx: idx },
                    replace: true,
                });
            }, 1000);
        }
        // fnToggleLoading(false);
    }, [token, fg, idx]);

    useEffect(() => {
        fnToggleLoading(true);

        return () => {
            fnToggleLoading(false);
        };
    }, []);

    useEffect(() => {
        if (pushToken) {
            fnPushToken();
            // Store 에 push 토큰 저장
            dispatch(actions.postPush({ push: pushToken }));
        }
    }, [pushToken]);

    return (
        <>
            <BaseLayout header={false} footer={false}>
                <div className="wrap wrap-join">
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '47%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                        }}
                    >
                        <p
                            className="caption bold"
                            style={{ color: 'var(--color-grayb-300)' }}
                        >
                            로그인 중 입니다...
                        </p>
                    </div>
                </div>
            </BaseLayout>
            {/* 로딩바 */}
            <Loading loading={isShow?.loading} />
        </>
    );
};
