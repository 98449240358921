import { CenterPopUp } from 'components/containers';
import { BaseButton, BaseImageButton } from 'components/elements';
import { ACCESS_TYPE } from 'constants/Mobile';

export const AccessPermissionPopup = ({
    show,
    accessType = ACCESS_TYPE.CAMERA,
    onClose,
    onOk,
}) => {
    const fnHandleClose = () => {
        if (onClose) onClose();
    };

    const fnHandleOk = () => {
        if (onOk) onOk();
    };

    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    return (
        <CenterPopUp
            show={show}
            width={'33.5rem'}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-center access-permission-popup">
                <div className="popup-wrap">
                    <div className="popup-head popup-close">
                        <p className="text">앱 접근 권한 안내</p>
                        <BaseImageButton
                            imageSrc={'/assets/images/icon-close.svg'}
                            altText={'닫기'}
                            onClick={fnHandleClose}
                        />
                    </div>
                    <div className="popup-content pt-16 pl-16 pr-16">
                        <div className="text-box gray">
                            <p>
                                컴팻홈은 아래와 같은 접근 권한을 필요로 합니다.
                                아래 항목에 동의하지 않아도 서비스를 이용하실 수
                                있으나, 일부 기능 사용에 제한이 있을 수
                                있습니다.{' '}
                            </p>
                        </div>
                        {(accessType === ACCESS_TYPE.CAMERA ||
                            accessType === ACCESS_TYPE.IOS_ALBUMN) && (
                            <div className="text-box">
                                <p className="text-title">
                                    1.
                                    <span className="point-bold">카메라 </span>
                                    <span className="point-g-choice point-bold">
                                        (선택)
                                    </span>
                                </p>
                                <p className="text-content">
                                    - 커뮤니티 및 가계부 내 메모장 사진 촬영
                                </p>

                                <p className="text-title">
                                    2.
                                    <span className="point-bold">사진 </span>
                                    <span className="point-g-choice point-bold">
                                        (선택)
                                    </span>
                                </p>
                                <p className="text-content">
                                    - 사진 이미지를 가져와 게시글에 첨부
                                </p>
                                <p className="text-content">
                                    - 기기에 사진 저장
                                </p>
                            </div>
                        )}
                        {accessType === ACCESS_TYPE.LOCATION && (
                            <div className="text-box">
                                <p className="text-title">
                                    1.
                                    <span className="point-bold">위치 </span>
                                    <span className="point-g-choice point-bold">
                                        (선택)
                                    </span>
                                </p>
                                <p className="text-content">
                                    - 선호 지역을 기반으로 게시글 필터링
                                </p>
                                <p className="text-content">
                                    - 게시글 작성 시 지역 등록
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="popup-footer r-15">
                        <BaseButton label={'다음'} onClick={fnHandleOk} />
                    </div>
                </div>
            </div>
        </CenterPopUp>
    );
};
