export const TYPES = {
    SET_POPULAR_LIST: 'SET_POPULAR_LIST',
}

const initState = {
    popularList: []
};

export default function PopularReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_POPULAR_LIST:
            return {
                ...state,
                popularList: action.payload,
            }


        default: 
            return state;
    }
};

export const actions = {
    setPopularList: (data) => ({
        type: TYPES.SET_POPULAR_LIST,
        payload: data,
    }),
}