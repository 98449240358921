import { Map } from 'immutable';

export const TYPES = {
    POST_LOCATION: 'POST_LOCATION',
    INIT_FILE: 'INIT_FILE',
    POST_FILE: 'POST_FILE',
    DELETE_FILE: 'DELETE_FILE',
    INIT_FILE_EXCEPTION: 'INIT_FILE_EXCEPTION',
    POST_FILE_EXCEPTION: 'POST_FILE_EXCEPTION',
    INIT_PERMISSION: 'INIT_PERMISSION',
    POST_PERMISSION: 'POST_PERMISSION',
    INIT_PURCHASE_TOKEN: 'INIT_PURCHASE_TOKEN',
    POST_PURCHASE_TOKEN: 'POST_PURCHASE_TOKEN',
};

const initState = Map({
    location: {
        latitude: '',
        longitude: '',
    },
    file: {
        filePath: null,
        fileListIdx: null,
    },
    fileException: '',
    purchase: {
        token: '', // 결제토큰
        price: 0, // 결제금액
        completeDate: '', // 결제완료일시
    },
    permission: {
        camera: -1,
        location: -1,
        notification: -1,
        contact: -1,
        albumn: -1,
    },
});

export default function CommonReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.POST_LOCATION:
            return state.set('location', action.location);
        case TYPES.INIT_FILE:
            return state.set('file', { filePath: null, fileListIdx: null });
        case TYPES.POST_FILE:
            const fileActionData = action.file;
            const oldFile = state.get('file');
            const newFilePath =
                oldFile.filePath === null
                    ? [fileActionData.filePath]
                    : [...oldFile.filePath, fileActionData.filePath];
            const newFileListIdx =
                oldFile.fileListIdx === null
                    ? [fileActionData.fileListIdx]
                    : [...oldFile.fileListIdx, fileActionData.fileListIdx];
            const newFile = {
                filePath: newFilePath,
                fileListIdx: newFileListIdx,
            };
            return state.set('file', newFile);
        case TYPES.DELETE_FILE: {
            const fileIndex = action.index;
            const oldFile = state.get('file');
            const newFileListIdx = oldFile?.fileListIdx?.filter(
                (item, idx) => idx !== fileIndex,
            );
            const newFilePath = oldFile?.filePath?.filter(
                (item, idx) => idx !== fileIndex,
            );
            // const listIndex = oldFile?.fileListIdx?.findIndex(item => item === Number(fileIndex))
            // const newFileListIdx = oldFile?.fileListIdx?.filter((item, idx) => idx !== listIndex);
            // const newFilePath = oldFile?.filePath?.filter((item, idx) => idx !== listIndex);
            const newFile2 = {
                filePath: newFilePath ? [...newFilePath] : null,
                fileListIdx: newFileListIdx ? [...newFileListIdx] : null,
            };
            return state.set('file', newFile2);
        }
        case TYPES.INIT_FILE_EXCEPTION:
            return state.set('fileException', '');
        case TYPES.POST_FILE_EXCEPTION:
            return state.set('fileException', action.fileException);
        case TYPES.INIT_PERMISSION:
            return state.set('permission', {
                camera: -1,
                location: -1,
                notification: -1,
                contact: -1,
            });
        case TYPES.POST_PERMISSION:
            const actionData = action.permission;
            const type = actionData.type;
            const result = actionData.result;
            let newPermission = { ...state.get('permission') };
            switch (type) {
                case 1001:
                    newPermission = { ...newPermission, camera: result };
                    break;
                case 1002:
                    newPermission = { ...newPermission, location: result };
                    break;
                case 1003:
                    newPermission = { ...newPermission, notification: result };
                    break;
                case 1004:
                    newPermission = { ...newPermission, contact: result };
                    break;
                case 1005:
                    newPermission = { ...newPermission, albumn: result }; // ios
                    break;
                default:
                    newPermission = { ...newPermission };
            }
            // alert(JSON.stringify(newPermission))
            return state.set('permission', newPermission);
        case TYPES.INIT_PURCHASE_TOKEN:
            return state.set('purchase', {
                token: '',
                price: 0,
                completeDate: '',
            });
        case TYPES.POST_PURCHASE_TOKEN:
            return state.set('purchase', action.purchase);

        default:
            return state;
    }
}

export const actions = {
    postLocation: ({ latitude, longitude }) => ({
        type: TYPES.POST_LOCATION,
        location: { latitude, longitude },
    }),
    initFile: () => ({ type: TYPES.INIT_FILE }),
    postFile: ({ filePath, fileListIdx }) => ({
        type: TYPES.POST_FILE,
        file: { filePath, fileListIdx },
    }),
    deleteFile: ({ index }) => ({ type: TYPES.DELETE_FILE, index }),
    initFileException: () => ({ type: TYPES.INIT_FILE_EXCEPTION }),
    postFileException: ({ type }) => ({
        type: TYPES.POST_FILE_EXCEPTION,
        fileException: type,
    }),
    initPermission: () => ({ type: TYPES.INIT_PERMISSION }),
    postPermission: ({ type, result }) => ({
        type: TYPES.POST_PERMISSION,
        permission: { type, result },
    }),
    initPurchaseToken: () => ({ type: TYPES.INIT_PURCHASE_TOKEN }),
    postPurchaseToken: ({ token, price, completeDate }) => ({
        type: TYPES.POST_PURCHASE_TOKEN,
        purchase: { token, price, completeDate },
    }),
};
