import React, {
    useRef,
    useCallback,
    useMemo,
    useEffect,
    useState,
} from 'react';
import ReactQuill from 'react-quill';
import QuillC from 'quill/core';
import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow'; //snow works, but need to import and register formats, and replace icons...
import Quill from 'quill';
import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';
import Underline from 'quill/formats/underline';
import Link from 'quill/formats/link';
import List, { ListItem } from 'quill/formats/list';
import Icons from 'quill/ui/icons';
import 'assets/css/react-custom-quill.css';
import 'react-quill/dist/quill.snow.css';
import linkifyHtml from 'linkify-html';
import { compressImg, upload } from 'utils/upload';
import { useNavigate } from 'react-router-dom';
import { AGENT } from 'constants/Mobile';
import { $openBrowser } from 'utils/mobile';

// 추가 폰트 크기 등록
const Size = Quill.import('attributors/style/size');
Size.whitelist = [
    '8px',
    '10px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '24px',
    '36px',
    '48px',
    '96px',
];
Quill.register(Size, true);

// 툴팁
const Tooltip = Quill.import('ui/tooltip');

export const Editor = ({
    common,
    height,
    values,
    setValue,
    uploadParam,
    placeholder,
    fileClick,
    imageFile,
}) => {
    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("## 이미지 파일 :: ", imageFile)
    // }, [imageFile])

    // 링크 클릭 시 이벤트를 처리하기 위한 함수
    const handleLinkClick = (event) => {
        event.preventDefault();
        // 클릭한 url
        const url = event.target.href;
        // baseUrl 가져오기
        const curLoc = window.location.origin;
        // 클릭한 url 에서 curLoc 을 자른 path
        const path = `${url.split(curLoc)[1]}`;

        if (url.includes(curLoc)) {
            // 링크가 내부 baseUrl 을 포함하고 있을 때
            navigate(path);
        } else {
            // 링크가 외부 url 일 때
            if (
                common.agentType === AGENT.ANDROID_APP ||
                common.agentType === AGENT.IOS_APP
            ) {
                // 앱일 때
                $openBrowser({ link: url });
            } else {
                // PC 일 때
                window.open(url);
            }
        }
    };
    QuillC.register({
        'modules/toolbar': Toolbar,
        'themes/snow': Snow,
        'formats/bold': Bold,
        'formats/italic': Italic,
        'formats/header': Header,
        'formats/underline': Underline,
        'formats/link': Link,
        'formats/list': List,
        'formats/list/item': ListItem,
        'ui/icons': Icons,
    });
    const quills = useRef(null);

    // 링크를 감지하고 a 태그로 변환하는 함수
    const transformContentWithLinks = (content) => {
        return linkifyHtml(content, {
            defaultProtocol: 'https',
            target: {
                url: '_blank',
            },
            // 링크 클릭 시 이벤트 처리
            linkAttributes: {
                onclick: handleLinkClick,
            },
            // 링크 css
            style: {
                color: '#0000EE',
                textDecoration: 'underline',
            },
        });
    };

    const fnImageUpload = useCallback(() => {
        let quillObj = quills.current?.getEditor();
        quillObj?.blur(); // 모바일 키보드 올라오는거 대응

        if (fileClick) fileClick();

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        if (!input) return;

        // input.click();
        input.onchange = async () => {
            const file = input.files;
            if (!file || !file.length) return;

            try {
                const compressFiles = await compressImg(file);
                // console.log("## compress files :: ", compressFiles)
                const formData = new FormData();
                compressFiles.forEach((file) =>
                    formData.append('upload', file)
                );
                // formData.append("upload", compressFiles);

                // console.log("## compress files 2 :: ", formData);

                let quillObj = quills.current?.getEditor();
                quillObj.focus();
                const range = quillObj?.getSelection();

                if (!range) {
                    console.error('No Selection range found');
                    return;
                }

                const params = {
                    actionType: uploadParam.ACTION_TYPE,
                    userIdx: uploadParam.userIdx,
                    contentIdx: uploadParam.contentIdx, // 등록시 0
                };

                // console.log("## params :: ", params)

                try {
                    const response = await upload(
                        compressFiles[0],
                        params,
                        (res) => {
                            const { data } = res;
                            // console.log("### 데이터 디버깅 :: ", data);

                            const result = {};
                            const file_path = data.file_path;

                            // console.log("## file_path : ", file_path)

                            quillObj?.insertEmbed(
                                range.index,
                                'image',
                                `${file_path}`
                            );
                            quillObj?.setSelection(range.index + 1);

                            // console.log("## quillObj :: ", quillObj)
                        }
                    );

                    // console.log("## response :: ", response)
                } catch (err) {
                    console.err(err);
                }
            } catch (err) {
                console.error(err);
            }
        };
    }, []);

    // const fnImageUpload = useCallback(() => {
    //     let quillObj = quills.current?.getEditor();
    //     quillObj?.blur(); // 모바일 키보드 올라오는거 대응
    //     if (fileClick) fileClick();

    //     const input = document.createElement("input");
    //     input.setAttribute("type", "file");
    //     input.setAttribute("accept", "image/*");
    //     // const input = editorFileInput?.current;

    //     if (input) {

    //       input.click();

    //       input.onchange = async () => {
    //         const file = input.files;
    //         if (!file || !file.length) return;

    //         const compressFiles = await compressImg(file);
    //         const formData = new FormData();
    //         compressFiles.forEach(file => formData.append("upload", file));
    //         // formData.append("upload", compressFiles);

    //         let quillObj = quills.current?.getEditor();
    //         quillObj.focus();
    //         const range = quillObj?.getSelection();

    //         if(!range) {
    //             console.error('No Selection range found');
    //             return;
    //         }

    //         try {
    //           // const result = await fileUploadApi({
    //           //   action_type: 8,
    //           //   data: formData
    //           // });
    //           const params = {
    //             actionType: uploadParam.ACTION_TYPE,
    //             userIdx: uploadParam.userIdx,
    //             contentIdx: uploadParam.contentIdx, // 등록시 0
    //           };

    //             try {
    //                 await upload(compressFiles[0], params, (res) => {
    //                     const { data } = res;
    //                     // console.log("### 데이터 디버깅 :: ", data);

    //                     const result = {};
    //                     const file_path = data.file_path;

    //                     quillObj?.insertEmbed(range.index, "image", `${file_path}`);
    //                     quillObj?.setSelection(range.index + 1);
    //                 });
    //             } catch (err) {
    //                 console.err(err);
    //             }

    //         } catch (err) {
    //           console.error(err);
    //         }
    //       }
    //     };
    // }, []);

    function convertYouTubeUrl(url) {
        let embedUrl = url;

        // 스트리밍 - live / 쇼츠 - shorts / 릴스 - reel
        // url을 해당 플랫폼 주소/embed/비디오Id 형식으로 변환

        if (url.includes('youtube.com') || url.includes('youtu.be')) {
            const videoIdMatch = url.match(
                /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|embed|shorts|live)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
            );
            if (videoIdMatch && videoIdMatch[1]) {
                const videoId = videoIdMatch[1];
                embedUrl = `https://www.youtube.com/embed/${videoId}`;
            } else {
                console.error('Invalid YouTube URL');
            }
        } else if (url.includes('instagram.com/reel/')) {
            const reelIdMatch = url.match(
                /instagram\.com\/reel\/([a-zA-Z0-9_-]+)/
            );
            if (reelIdMatch && reelIdMatch[1]) {
                const reelId = reelIdMatch[1];
                embedUrl = `https://www.instagram.com/embed/reel/${reelId}/`;
            } else {
                console.error('Invalid Instagram Reel URL');
            }
        } else {
            console.error('Unsupported URL format');
        }

        return embedUrl;
    }

    const insertVideo = (url) => {
        return new Promise((resolve, reject) => {
            let quillObj = quills.current?.getEditor();
            quillObj.focus(); // selection을 위한 포커스
            const range = quillObj?.getSelection();
            if (url && range) {
                const convertedUrl = convertYouTubeUrl(url); // 쇼츠나 스트리밍 영상인경우 /embed/ 붙여주기위한 변환
                let width = 360;
                let height = 225;

                if (url.includes('/shorts/')) {
                    // 쇼츠인 경우 높이 너비 변경 (길쭉하게)
                    width = 360;
                    height = 640;
                }
                quillObj?.clipboard.dangerouslyPasteHTML(
                    range.index,
                    `<iframe class="ql-video" width="${width}px" height="${height}px"  src="${convertedUrl}" frameborder="0" allowfullscreen></iframe>`
                );
                quillObj?.setSelection(range.index + 1);
            }
            resolve();
        });
    };

    const modules = useMemo(() => {
        return {
            toolbar: {
                container: [
                    // 글꼴 스타일과 크기
                    // [{ size: Size.whitelist }],

                    // 텍스트 스타일: 굵게, 기울임꼴, 밑줄, 취소선
                    // ["bold", "italic", "underline", "strike", "blockquote"],

                    // 색상 선택: 텍스트 색상과 배경색
                    // [{ color: [] }, { background: [] }],

                    // 헤딩: 제목 크기
                    // [{ header: [1, 2, 3, 4, 5, false] }],

                    // 인용문, 코드블록
                    // ["blockquote", "code-block"],

                    // 리스트: 순서가 있는 리스트, 순서가 없는 리스트
                    // [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],

                    // 정렬: 왼쪽, 가운데, 오른쪽, 정렬 없음
                    // [{ align: [] }],

                    // 이미지, 비디오
                    ['image', 'video'],

                    // 기타
                    // ["clean"] // 모든 스타일 제거
                ],
                handlers: {
                    image: fnImageUpload,
                },
            },
        };
    }, [fnImageUpload]);

    const handleChange = (content, delta, source, editor) => {
        // 링크를 감지하고 a 태그로 변환
        const converted = content.replaceAll('&', '');
        const htmlWithLinks = transformContentWithLinks(converted);
        setValue({ ...values, content: htmlWithLinks }); // 사용자 입력에 의한 변경만 상태 업데이트
    };

    useEffect(() => {
        if (quills.current) {
            const quill = quills.current.getEditor();
            quill.clipboard.dangerouslyPasteHTML(values?.content);
            // 비디오 툴바
            quill.getModule('toolbar').addHandler('video', () => {
                let myBounds = quill.getBounds(50, 50);
                let myTooltip = new Tooltip(quill);

                myTooltip.root.style.left = '15px !important';
                myTooltip.root.style.top = '22px !important';
                myTooltip.root.classList.add('ql-video-tooltip');
                myTooltip.root.setAttribute('data-mode', 'video');

                // '<a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>',
                // '<input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL">',
                // '<a class="ql-action"></a>',
                // '<a class="ql-remove"></a>',

                // 비디오 입력 인풋
                const input = document.createElement('input');
                input.id = 'videoLinkInput';
                input.type = 'text';
                input.dataFormula = 'e=mc^2';
                input.dataLink = 'https://quilljs.com';
                input.dataVideo = 'Embed URL';
                input.placeholder = 'Embed URL';
                input.dataPlaceholder = 'Embed URL';
                // input.style.width = '200px';
                input.style.display = 'block';

                // 저장 버튼 클릭시 동영상 임베드
                const button = document.createElement('a');
                button.text = 'Save';
                button.addEventListener('click', async () => {
                    const url = input.value;
                    await insertVideo(url).then(() => myTooltip.hide()); // 임베드완료 후 툴팁 닫기
                });
                const cancel = document.createElement('a');
                cancel.text = 'Cancel';
                cancel.style.marginLeft = '13px';
                cancel.addEventListener('click', () => {
                    myTooltip.hide(); // 취소 버튼 클릭시 툴팁 닫기
                });

                myTooltip.root.appendChild(input);
                myTooltip.root.appendChild(button);
                myTooltip.root.appendChild(cancel);

                // undefined뜨는거 삭제
                const undefinedText =
                    myTooltip.root.querySelector(
                        '#videoLinkInput'
                    ).previousSibling;
                myTooltip.root.removeChild(undefinedText);

                myTooltip.show();
                // console.log(myTooltip);
                myTooltip.position(myBounds);
                input.focus();
            });
            // 링크 툴바
            quill.getModule('toolbar').addHandler('link', (value) => {
                let quillObj = quills.current?.getEditor();
                quillObj.focus(); // selection을 위한 포커스
                const range = quillObj?.getSelection();
                if (value) {
                    if (range == null || range.length == 0) return;
                    let preview = quillObj?.getText(range);
                    if (
                        /^\S+@\S+\.\S+$/.test(preview) &&
                        preview.indexOf('mailto:') !== 0
                    ) {
                        preview = 'mailto:' + preview;
                    }
                    let tooltip = quillObj?.theme.tooltip;
                    tooltip.edit('link', preview);
                } else {
                    quillObj?.format('link', false);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (imageFile) {
            let quillObj = quills.current?.getEditor();
            quillObj.focus(); // 모바일 키보드 올라오는거 대응
            const range = quillObj?.getSelection();
            quillObj?.insertEmbed(range.index, 'image', `${imageFile}`);
            quillObj?.setSelection(range.index + 1);
        }
    }, [imageFile]);

    useEffect(() => {
        if (quills.current) {
            const quill = quills.current.getEditor();
            quill.root.setAttribute('data-placeholder', placeholder); // placeholder 속성 업데이트
            quill.root.placeholder = placeholder; // Quill root의 placeholder를 업데이트
        }
    }, [placeholder]);

    return (
        <>
            <ReactQuill
                ref={quills}
                style={{ width: '100%' }}
                modules={modules}
                theme="snow"
                value={values?.content}
                onChange={handleChange}
            />
        </>
    );
};
