import { Button } from 'antd-mobile';
import { useEffect } from 'react';

export function BaseButton({
    label,
    disabled = false,
    className = '',
    onClick,
}) {
    return (
        <Button
            className={`cmp-base-button ${disabled && 'disabled'} ${className}`}
            size="mini"
            disabled={disabled}
            onClick={onClick}
        >
            <div className={`cmp-base-button-label ${disabled && 'disabled'}`}>
                {label}
            </div>
        </Button>
    );
}

export function BaseIconButton({
    children,
    disabled = false,
    className = '',
    onClick,
}) {
    return (
        <button
            type="button"
            className={className}
            disabled={disabled}
            onClick={onClick}
            style={disabled ? { cursor: 'default' } : {}}
        >
            {children}
        </button>
    );
}

export function BaseSelectButton({
    label,
    disabled = false,
    className = '',
    onClick,
}) {
    return (
        <button
            type="button"
            className={`cmp-base-select-button ${className}`}
            onClick={onClick}
        >
            {label}
            <img
                src={`/assets/images/arrow-bottom${className === 'active' ? `-active` : `-inactive`}.svg`}
                alt=""
            />
        </button>
    );
}

export function BaseImageButton({
    className = '',
    imgClassName = '',
    disabled = false,
    children,
    imageSrc,
    altText,
    onClick,
}) {
    return (
        <button
            className={className}
            type="button"
            onClick={onClick}
            disabled={disabled}
        >
            <img className={imgClassName} src={imageSrc} alt={altText} />
            {children}
        </button>
    );
}

export function BaseTextButton({
    className = '',
    label,
    onClick,
    disabled = false,
}) {
    return (
        <button
            disabled={disabled}
            className={className}
            type="button"
            onClick={onClick}
        >
            {label}
        </button>
    );
}

export function BaseConfirmButton({
    leftClassName = '',
    rightClassName = '',
    leftLabel,
    rightLabel,
    leftOnClick,
    rightOnClick,
}) {
    return (
        <>
            <button
                className={leftClassName}
                type="button"
                onClick={leftOnClick}
            >
                {leftLabel}
            </button>
            <button
                className={rightClassName}
                type="button"
                onClick={rightOnClick}
            >
                {rightLabel}
            </button>
        </>
    );
}
