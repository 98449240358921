/**
 * @author kkazzong
 * @desc 헤더
 * pathname에 따라 헤더 결정
 */

import { useLocation } from 'react-router-dom';
import {
    URL_HOME,
    URL_COMMUNITY,
    URL_DIARY,
    URL_WALLET,
} from 'routes/RouteUrl';
import { BaseHeaderHome } from './BaseHeaderHome';
import { BaseHeaderCommunity } from './BaseHeaderCommunity';
import { BaseHeaderNote } from './BaseHeaderNote';
import { BaseHeaderAction } from './BaseHeaderAction';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPushListApi } from 'api/common';
import {
    COMMUNITY_PUSH_TYPE,
    NOTE_PUSH_TYPE,
    NOTICE_PUSH_TYPE,
} from 'constants/Push';
import { useAuth } from 'hooks/AuthContext';
import { useLocalStorage, getLocalStorage } from 'hooks/useLocalStorage';
import { actions as pushActions } from 'store/push-reducer';

import { useCommonContext } from 'hooks/CommonContext';
import { actions } from 'store/alarm-reducer';
import { $get, $getIOS, isMobile } from 'utils/mobile';
import { AGENT } from 'constants/Mobile';

export function BaseHeader() {
    const location = useLocation();
    const push = useSelector((state) => state.push.get('push'));
    const { noticeAlarmList, communityAlarmList, noteAlarmList } = useSelector(
        (state) => state.alarm
    );
    const { pathname } = location;
    const dispatch = useDispatch();
    const { user } = useAuth();
    const userIdx = user?.user_idx || undefined;
    const [pushReadIdx, setPushReadIdx] = useLocalStorage(
        'persist:pushReadIdx',
        []
    );
    const [pushs, setPushs] = useState(null);
    const [hasAlram, setHasAlram] = useState(false);

    const fnGetPushList = async () => {
        const params = {};

        try {
            const res = await getPushListApi({ userIdx, params });
            const { data_list } = res?.data;

            const boardList = data_list.filter((item) =>
                NOTICE_PUSH_TYPE.map((item) => item.type).includes(
                    item.send_type
                )
            );

            const communityList = data_list.filter((item) =>
                COMMUNITY_PUSH_TYPE.map((item) => item.type).includes(
                    item.send_type
                )
            );

            const walletList = data_list.filter((item) =>
                NOTE_PUSH_TYPE.map((item) => item.type).includes(item.send_type)
            );

            const pushes = {
                boardList,
                communityList,
                walletList,
            };

            setPushs(pushes);
            dispatch(actions.setNoticeAlarmList(boardList));
            dispatch(actions.setCommunityAlarmList(communityList));
            dispatch(actions.setNoteAlarmList(walletList));

            if (Array.isArray(noteAlarmList)) {
                const allPushList = [
                    ...noteAlarmList,
                    ...communityAlarmList,
                    ...noteAlarmList,
                ];

                if (allPushList.length === pushReadIdx.length) {
                    dispatch(pushActions.postPush({ push: pushes }));
                } else {
                    dispatch(pushActions.postPush({ push: null }));
                }
            }

            if (!push) dispatch(pushActions.postPush({ push: pushes }));
        } catch (err) {
            console.error(err);
        }
    };

    // 알림 리스트 읽은 내역 체크
    const checkPushHandle = () => {
        if (!push?.boardList) return;

        const pushList = [
            ...push?.boardList,
            ...push?.communityList,
            ...push?.walletList,
        ];

        const result = pushList?.every((item) =>
            pushReadIdx.includes(item.idx)
        );

        return !result;
    };

    useEffect(() => {
        if (!push) fnGetPushList();
    }, [push]);

    // 알람 핸들링
    useEffect(() => {
        const result = checkPushHandle();
        setHasAlram(result);
    }, [pushReadIdx, push]);

    const Header = () => {
        switch (pathname) {
            case URL_HOME:
                return <BaseHeaderHome />;
            case URL_COMMUNITY:
                return <BaseHeaderCommunity />;
            case URL_DIARY:
            case URL_WALLET:
                return <BaseHeaderNote menuChoice={pathname} />;
            default:
                return <BaseHeaderHome />;
        }
    };

    return (
        <header
            style={
                pathname === URL_DIARY || pathname === URL_WALLET
                    ? { position: 'static' }
                    : { position: 'fixed', transform: 'translate(-50%, 0)' }
            }
            className={
                pathname === URL_HOME ? 'layout-header2' : 'layout-header'
            }
        >
            <Header />
            <BaseHeaderAction hasAlram={hasAlram} pathname={pathname} />
        </header>
    );
}
