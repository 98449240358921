import { DatePickerView } from 'antd-mobile';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const DatePickerViewWrapper = styled(DatePickerView)`
    // datepicker selected area background
    .adm-picker-view-mask-middle {
        background: rgba(189, 195, 199, 0.3);
        border-top: 0;
        border-bottom: 0;
        border-radius: 0.8rem;
        height: 3.6rem;
    }
    .adm-picker-view-column-item {
        font-size: 2rem;
        padding: 0 0.6rem;
        /* height: 3.6rem; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const now = new Date();
const minDate = new Date();
minDate.setFullYear(2023, 0, 1);

const labelRenderer = (type, data) => {
    switch (type) {
        case 'year':
            return data + '년';
        case 'month':
            return data + '월';
        case 'day':
            return data + '일';
        case 'hour':
            return data + '시';
        case 'minute':
            return data + '분';
        case 'second':
            return data + '초';
        default:
            return data;
    }
};

export function IosDatePicker({ onChange }) {
    const [value, setValue] = useState(now);

    useEffect(() => {
        if (onChange) {
            const d = dayjs(now);

            let yyyy = d.format('YYYY');
            let mm = d.format('MM');
            let dd = d.format('DD');

            onChange(`${yyyy}${mm}${dd}`);
        }
    }, []);

    return (
        <DatePickerViewWrapper
            style={{ fontSize: '2em' }}
            min={minDate}
            value={value}
            renderLabel={labelRenderer}
            onChange={(date) => {
                setValue(date);
                const d = dayjs(date);

                let yyyy = d.format('YYYY');
                let mm = d.format('MM');
                let dd = d.format('DD');
                if (onChange) onChange(`${yyyy}${mm}${dd}`);
            }}
        />
    );
}
