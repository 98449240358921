import { useAuth } from 'hooks/AuthContext';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions as pushActions } from 'store/push-reducer';

export const ClientSocket = ({ common }) => {
    const client = useRef(undefined);
    const dispatch = useDispatch();
    const { user } = useAuth();
    const userIdx = user?.user_idx;

    const [sockjs, setSockjs] = useState(undefined);
    const [connected, setConnected] = useState(false);

    const socketConnect = () => {
        const url = process.env.REACT_APP_SOCKET_BASEURL.replace(
            ':user_idx',
            userIdx
        );

        const socket = new WebSocket(`${url}`);
        socket.onopen = () => {
            // console.log(`[socket] connected....`);
            setSockjs(socket);
            setConnected(true);
            // const data_info = {
            //   boardList: [],
            //   walletList: [],
            // };
            // data_info.boardList.push({  idx:1, urgent_fg: 1, title: '공지 테스트(s)', desc: '상세내용확인' })
            // //data_info.communityList.push({  urgent_fg: 1, title: '커뮤니티 테스트', desc: '상세내용확인' })
            // data_info.walletList.push({  idx:2, fg: 1, title: '일지 테스트(s)', desc: '상세내용확인', date: '2024-01-18 00:00:00', user_nickname: '닉넴테스트', profile_img_path: null})
            // data_info.walletList.push({  idx:3, fg: 2, title: '가계부 테스트(s)', desc: '상세내용확인', date: '2024-01-15 00:00:00', user_nickname: '닉넴테스트', profile_img_path: null})
            // dispatch(pushActions.postPush({ push: data_info }));
        };
        socket.onmessage = function (e) {
            // console.log(`[socket] message ::: `, JSON.parse(e.data));
            const parsedData = e.data?.length > 0 ? JSON.parse(e.data) : {};
            // const { data_info } = parsedData;
            // data_info.boardList.push({  idx:1, urgent_fg: 1, title: '공지 테스트', desc: '상세내용확인' })
            // data_info.communityList.push({  urgent_fg: 1, title: '커뮤니티 테스트', desc: '상세내용확인' })
            // data_info.walletList.push({  idx:2, fg: 1, title: '일지 테스트', desc: '상세내용확인', date: '2024-01-18 00:00:00', user_nickname: '닉넴테스트', profile_img_path: null})
            // data_info.walletList.push({  idx:3, fg: 2, title: '가계부 테스트', desc: '상세내용확인', date: '2024-01-15 00:00:00', user_nickname: '닉넴테스트', profile_img_path: null})
            dispatch(pushActions.postPush({ push: parsedData }));
        };
        socket.onclose = () => {
            // console.log(`[socket] closed... reconnect after 1sec`);
            setTimeout(function () {
                socketConnect();
            }, 1000);
        };
    };

    const socketDisconnect = () => {
        //alert('disconn!?!')
        sockjs.close();
    };

    useEffect(() => {
        if (userIdx && userIdx !== 0) socketConnect();
    }, [userIdx]);

    useEffect(() => {
        return () => {
            if (connected) socketDisconnect();
        };
    }, [connected]);

    return <></>;
};
