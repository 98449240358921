import { TermsAgreeModal } from 'components/containers/TermsAgreeModal';
import { BaseLink, BaseTextButton } from 'components/elements';
import {
    KAKAO,
    NEEDHOME,
    COMEPETHOME,
    CHECK,
    NAVER,
    APPLE,
    GOOGLE,
    WITHDRAW_COMPLETE,
    WITHDRAW_INFO,
} from 'constants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertModal } from 'components/containers';
import { $get, $getIOS } from 'utils/mobile';
import { postMemberProfileApi } from 'api/member';
import { getRandomProfilePath } from 'utils/utils';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { BaseLayout } from 'components/layouts';
import { postShareCodeApi } from 'api/setting';
import { AGENT } from 'constants/Mobile';
import { useAuth } from 'hooks/AuthContext';
import { URL_HOME } from 'routes/RouteUrl';

export const JoinLogin = ({ onMoveStep, complete, userIdx, common }) => {
    const AGENT_TYPE = common.agentType;
    const { user, login } = useAuth();
    const navigate = useNavigate();
    const [errorData, setErrorData] = useState(null);
    const [onceClick, setOnceClick] = useState(true);
    const { ErrorModal } = useErrorHandler({ errorData });

    const { state } = useLocation();
    const [openModal, setOpenModal] = useState({
        openWithdrawAlertModal: state?.withdrawComplete ? true : false,
    });

    const fnKakaoLogIn = async () => {
        if (
            navigator.userAgent.match(/pethome.android.20241118/i) ||
            navigator.userAgent.match(/pethome.ios.20241118/i)
        ) {
            // kakao sync
            if (window?.Kakao) {
                window.Kakao.Auth.authorize({
                    redirectUri: process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URI,
                });
            } else {
                const kakaoAuthUrl = process.env.REACT_APP_KAKAO_LOGIN_URL;
                window.location.href = kakaoAuthUrl;
            }
        } else {
            const kakaoAuthUrl = process.env.REACT_APP_KAKAO_LOGIN_URL;
            window.location.href = kakaoAuthUrl;
            // console.log("## kakaoAuthUrlkakaoAuthUrl :: ", kakaoAuthUrl);
            // return;
        }
    };

    const fnNaverLogIn = () => {
        const naverAuthUrl = process.env.REACT_APP_NAVER_LOGIN_URL;
        window.location.href = naverAuthUrl;
    };

    const fnGoogleLogIn = () => {
        const googleAuthUrl = process.env.REACT_APP_GOOGLE_LOGIN_URL;
        window.location.href = googleAuthUrl;
    };

    const fnAppleLogIn = () => {
        const appleAuthUrl = process.env.REACT_APP_APPLE_LOGIN_URL;
        if (onceClick) {
            window.location.href = appleAuthUrl;
        }
        setOnceClick(false);
        setTimeout(() => {
            setOnceClick(true); // 1초후 원복
        }, 1000);
    };

    const fnCloseAlert = () => {
        setOpenModal((prev) => ({ ...prev, openWithdrawAlertModal: false }));
    };

    useEffect(() => {
        const kakaoJsKey = process.env.REACT_APP_KAKAO_LOGIN_JS_KEY;

        // SDK는 한 번만 초기화
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(kakaoJsKey);
            //console.log(window.Kakao.isInitialized());
        }
    }, []);

    useEffect(() => {
        if (!complete || !userIdx) return;

        // 회원가입시 정상적으로 토큰 발급 받은 경우 랜덤 프로필 세팅
        const fnPostProfile = async () => {
            const randomFilePath = getRandomProfilePath();
            const data = {
                user_idx: userIdx,
                profile_img_path: randomFilePath,
            };

            try {
                await postMemberProfileApi({ data });
            } catch (err) {
                console.error(err);
                setErrorData(err);
            }
        };

        fnPostProfile();

        // 수첩공유하기로 랜딩한 회원인 경우 수첩공유
        if (!common.mobile) return;
        if (AGENT_TYPE === AGENT.ANDROID_APP) {
            // 안드로이드 앱
            const invite = $get('invite');
            if (invite && invite !== '') {
                const data = {
                    user_idx: userIdx,
                    share_code: invite,
                };
                if (userIdx && invite) {
                    postShareCodeApi({ data })
                        .then((res) => {})
                        .catch((err) => {
                            console.error(err);
                            //setErrorData(err)
                        })
                        .finally(() => {
                            //fnToggleLoading(false)
                        });
                }
            }
        }
    }, [complete, userIdx]);

    useEffect(() => {
        if (user?.token && user?.user_idx) {
            navigate(URL_HOME);
            // IOS 앱일 때
        } else if (AGENT_TYPE === AGENT.IOS_APP) {
            $getIOS('userToken', (userToken) => {
                $getIOS('userIdx', (userIdx) => {
                    if (userToken && userIdx) {
                        login({ token: userToken, user_idx: userIdx });
                        navigate(URL_HOME);
                    }
                });
            });

            // 안드로이드 앱일 때
        } else if (AGENT_TYPE === AGENT.ANDROID_APP) {
            const userToken = $get('userToken');
            const userIdx = $get('userIdx');
            if (userToken && userIdx) {
                login({ token: userToken, user_idx: userIdx });
                navigate(URL_HOME);
            }
        }
    }, []);

    return (
        <>
            <BaseLayout header={false} footer={false}>
                <div className="wrap" style={{}}>
                    <div className="member">
                        <div className="login agreement">
                            <h1 className="need-home-text login-page">
                                {COMEPETHOME}
                                <p>{NEEDHOME}</p>
                            </h1>
                            <div className="login-img">
                                <img src="/assets/images/img-2.jpg" alt="" />
                            </div>
                            <div className="login-button-wrap">
                                <ul>
                                    {AGENT_TYPE !== AGENT.IOS_APP &&
                                        AGENT_TYPE !== AGENT.IOS_WEB && (
                                            <li>
                                                <BaseLink
                                                    onClick={fnGoogleLogIn}
                                                    className={'google-login'}
                                                >
                                                    {GOOGLE}
                                                </BaseLink>
                                            </li>
                                        )}
                                    <li>
                                        <BaseLink
                                            onClick={fnKakaoLogIn}
                                            className={'kakao-login'}
                                        >
                                            {KAKAO}
                                        </BaseLink>
                                    </li>
                                    <li>
                                        <BaseLink
                                            onClick={fnNaverLogIn}
                                            className={'naver-login'}
                                        >
                                            {NAVER}
                                        </BaseLink>
                                    </li>
                                    {AGENT_TYPE !== AGENT.ANDROID_APP &&
                                        AGENT_TYPE !== AGENT.ANDROID_WEB && (
                                            <li>
                                                <BaseLink
                                                    onClick={fnAppleLogIn}
                                                    className={'apple-login'}
                                                >
                                                    {APPLE}
                                                </BaseLink>
                                            </li>
                                        )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {complete && (
                    <TermsAgreeModal onClick={onMoveStep} userIdx={userIdx} />
                )}
                {
                    // 탈퇴 완료 후 로그인 페이지 온 경우 탈퇴완료 메시지 표시
                    <AlertModal
                        show={openModal.openWithdrawAlertModal}
                        label={WITHDRAW_COMPLETE}
                        subtitle={WITHDRAW_INFO}
                        footer={
                            <BaseTextButton
                                className="point-g"
                                label={CHECK}
                                onClick={fnCloseAlert}
                            />
                        }
                        //onOk={fnCloseAlert}
                    />
                }

                {/* 에러 */}
                {ErrorModal}
            </BaseLayout>
        </>
    );
};
