export const TYPES = {
    SET_NOTICE_ALARM_LIST: 'SET_NOTICE_ALARM_LIST',
    SET_COMMUNITY_ALARM_LIST: 'SET_COMMUNITY_ALARM_LIST',
    SET_NOTE_ALARM_LIST: 'SET_NOTE_ALARM_LIST',
};

const initState = {
    alarmList: {
        noticeAlarmList: [],
        communityAlarmList: [],
        noteAlarmList: [],
    },
};

export default function AlarmReducer(state = initState, action) {
    switch (action.type) {
        case TYPES.SET_NOTICE_ALARM_LIST:
            return {
                ...state,
                noticeAlarmList: action.payload,
            };

        case TYPES.SET_COMMUNITY_ALARM_LIST:
            return {
                ...state,
                communityAlarmList: action.payload,
            };

        case TYPES.SET_NOTE_ALARM_LIST:
            return {
                ...state,
                noteAlarmList: action.payload,
            };

        default:
            return state;
    }
}

export const actions = {
    setNoticeAlarmList: (alarm) => ({
        type: TYPES.SET_NOTICE_ALARM_LIST,
        payload: alarm,
    }),

    setCommunityAlarmList: (alarm) => ({
        type: TYPES.SET_COMMUNITY_ALARM_LIST,
        payload: alarm,
    }),

    setNoteAlarmList: (alarm) => ({
        type: TYPES.SET_NOTE_ALARM_LIST,
        payload: alarm,
    }),
};
