import { BaseImageButton, BaseButton } from 'components/elements';
import { CenterPopUp } from './CenterPopUp';
import { CLOSE } from 'constants';
import { useEffect } from 'react';

export function PetAddInfoModal({ show, onOk, onClose }) {
    const fnHandleClose = () => {
        if (onClose) onClose();
    };
    const fnHandleOk = () => {
        if (onOk) onOk();
    };
    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    // 스크롤 핸들링
    const preventScroll = (e) => {
        e.preventDefault();
    };

    // 스크롤 핸들링
    useEffect(() => {
        if (show) {
            window.addEventListener('wheel', preventScroll, { passive: false });
            window.addEventListener('touchmove', preventScroll, {
                passive: false,
            });
        } else {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        }

        return () => {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        };
    }, [show]);

    return (
        <CenterPopUp
            show={show}
            width={'335px'}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-layer popup-diary pet-add-info-modal">
                <div className="popup-wrap type-center">
                    <div className="popup-head popup-close">
                        <div className="popup-title-wrap-diary">
                            <img src="/assets/images/pet_icon.svg" alt="logo" />
                            <p className="text">
                                컴펫홈! 동물 가족을 알려주세요.
                            </p>
                        </div>
                        <BaseImageButton
                            className="popup-title-close-diary align center vm"
                            imageSrc={'/assets/images/icon-close.svg'}
                            altText={CLOSE}
                            onClick={fnHandleClose}
                        />
                    </div>
                    <div className="popup-content pd-25 pt-24 pb-24">
                        <div className="text-box">
                            <p>어서오세요! </p>
                            <p>
                                컴펫홈의 일지와 가계부는 동물 가족의 하루를
                                기록할 수 있도록 만들어졌어요.
                            </p>
                            <p>
                                동물 가족을 등록하고, 우리의 하루를 작성해봐요!
                            </p>
                        </div>
                    </div>
                    <div className="popup-footer">
                        <BaseButton
                            className="active"
                            altText={CLOSE}
                            onClick={fnHandleOk}
                            label={'우리 가족은요..'}
                        ></BaseButton>
                    </div>
                </div>
            </div>
        </CenterPopUp>
    );
}
