import { useState } from 'react';

export function BaseInput({
    id = '',
    name = '',
    checked = false,
    disabled = false,
    className = '',
    type = undefined,
    placeholder = '',
    value,
    maxLength,
    onChange,
    onKeyDown = undefined,
    onKeyUp = undefined,
    onClick = undefined,
    onBlur = undefined,
    onFocus = undefined,
}) {
    const handleInput = (e) => {
        if (!onChange) return;
        if (type === 'checkbox') {
            onChange(e.target.checked);
        } else {
            onChange(e.target.value);
        }
    };

    const handleKeyDown = (e) => {
        if (onKeyDown) onKeyDown(e);
    };
    const handleKeyUp = (e) => {
        if (onKeyUp) onKeyUp(e);
    };

    const handleClick = () => {
        if (onClick) onClick();
    };

    const handleBlur = () => {
        if (onBlur) onBlur();
    };
    const handleFocus = () => {
        if (onFocus) onFocus();
    };

    return (
        <input
            id={id}
            name={name}
            checked={checked}
            className={className || ''}
            type={type || 'text'}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength || ''}
            onChange={(e) => handleInput(e)}
            onKeyDown={(e) => handleKeyDown(e)}
            onKeyUp={(e) => handleKeyUp(e)}
            onClick={handleClick}
            disabled={disabled}
            onBlur={handleBlur}
            onFocus={handleFocus}
        />
    );
}
