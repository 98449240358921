export function BaseRadio({
    className = '',
    name = '',
    id = '',
    value = '',
    defaultChecked = false,
    onChange = undefined,
}) {
    const fnSetValue = () => {
        if (onChange) onChange(id);
    };
    return (
        <>
            <input
                type="radio"
                name={name}
                id={id}
                className={className}
                checked={defaultChecked}
                onChange={fnSetValue}
            />
            <label htmlFor={id}>
                <span className="ico-radio"></span>
                {value}
            </label>
        </>
    );
}
