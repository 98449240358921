/**
 * send_type : 1, 2, 3 - 공지
 * 4 ~ 7 - 커뮤니티
 * 8 ~ 12 - 수첩
 */
export const NOTICE_PUSH_TYPE = [
    {
        type: 1,
        label: '공지사항',
        desc: '상세 내용을 확인해보세요.',
        className: 'label-text-notice',
    },
    {
        type: 2,
        label: '이벤트',
        desc: '상세 내용을 확인해보세요.',
        className: 'label-text-guide',
    },
    {
        type: 3,
        label: '안내사항',
        desc: '상세 내용을 확인해보세요.',
        className: 'label-text-event',
    },
];
export const COMMUNITY_PUSH_TYPE = [
    {
        type: 4,
        desc: '아래를 눌러 확인해보세요.',
        label: '👍 내 글을 좋아하는 사람이 있어요',
    },
    {
        type: 5,
        desc: '아래를 눌러 확인해보세요.',
        label: '💬 새로운 댓글이 있어요',
    },
    {
        type: 6,
        desc: '나를 관심이웃에 추가한 새로운 친구가 생겼어요. 앞으로도 멋진 활동 부탁해요!',
        label: '💬 컴펫홈 친구의 새로운 관심 콕!',
    },
    {
        type: 7,
        desc: '아래를 눌러 확인해보세요.',
        label: '🆘 우리 동네 실종 사건! 가족을 찾아주세요',
    },
];
export const NOTE_PUSH_TYPE = [
    {
        type: 8,
        label: '🗒️ #{item} 일지에 새로운 내용이 있어요',
        className: 'log-book',
    },
    {
        type: 9,
        label: '✏️ #{item} 일지의 내용이 수정되었어요',
        className: 'log-book',
    },
    {
        type: 10,
        label: '💰 #{item} 가계부에 새로운 내용이 있어요',
        className: 'account-book',
    },
    {
        type: 11,
        label: '✍️ #{item} 가계부의 내용이 수정되었어요',
        className: 'account-book',
    },
    {
        type: 12,
        label: '📊 #{item} 가계부의 리포트가 도착했어요',
        className: 'account-book',
    },
];
