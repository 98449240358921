export const FORBIDDEN_NICKNAME = [
    '운영자',
    '운영',
    'GM',
    '스텝',
    '관리',
    '관리자',
    '관리인',
    '감독',
    '어드민',
    '매니저',
    '메니저',
    '매니져',
    '메니져',
    'admin',
    'administrator',
    'manager',
    'test',
    'tester',
    '테스트',
    '테스터',
    'staff',
    '공식',
    '계정',
    '공식계정',
    '공무원',
    '보안관',
    '보안',
    '경찰관',
    '경찰',
    'police',
    'support',
    '서포트',
    'official',
    '오피셜',
    '대표계정',
    '대표',
    'D쥐고',
    'D지고',
    'jonna',
    'jot같',
    'mi쳤',
    'tlqkf',
    'wlfkf',
    '느갭',
    '느검',
    '니그로',
    '도태남',
    '도태녀',
    '개독',
    '개돼지',
    '개련',
    '개부랄',
    '개지랄',
    '개새기',
    '개새끼',
    '개섹',
    '개슬람',
    '풀발',
    '씹선',
    '개저씨',
    '개줌마',
    '계새끼',
    '괘새끼',
    '김치녀',
    '김치남',
    '김치놈',
    '김치년',
    '한녀',
    '한남들',
    '그남들',
    '자들자들',
    '된장녀',
    '피싸개',
    '앙기모띠',
    '소추',
    '퍄퍄',
    '형보수지',
    '김여사',
    '게이새끼',
    '여적여',
    '자적자',
    '남적남',
    '보적보',
    '보슬아치',
    '보징어',
    '엑윽',
    '헤으응',
    '이기야',
    '부왘',
    '보픈카',
    '상폐녀',
    '배빵',
    '누보햄',
    '자박꼼',
    '냄저',
    '빨갱',
    '뷔페미',
    '꼴페',
    '문재앙',
    '윤재앙',
    '쿰척',
    'ㅗㅜㅑ',
    '껒여',
    '꺼지세요',
    '꺼져요',
    '설거지론',
    '퐁퐁남',
    '퐁퐁녀',
    '남창',
    '년놈',
    '노알라',
    '느그',
    '느금',
    '눈깔파',
    '늬믜',
    '늬미',
    '니년',
    '니믜',
    '니미럴',
    '느개미',
    '느갬',
    '닝기리',
    '닥쳐라',
    '닥치세',
    '머가리',
    '뒈져',
    '뒤져라',
    '뒤져버',
    '뒤지고싶',
    '뒤지길',
    '뒤진다',
    '뒤질',
    '디져라',
    '디졌',
    '디지고',
    '디질',
    '딴년',
    '똥꼬충',
    '똥남아',
    'xy들',
    'xx들',
    '련들',
    '롬들',
    'ㅁㅊ',
    'ㅁ친',
    '맘충',
    '미시친발',
    '미쳤니',
    '미친새',
    '미친색',
    '줘패',
    '꼬추',
    '색퀴',
    'ㅅ끼',
    '한남들',
    '흉자',
    'GR도',
    '미핀놈',
    'G랄',
    'd져',
    'ㅂ크',
    '발놈',
    '병1신',
    '병신',
    '봊',
    '보전깨',
    '싸개',
    '븅신',
    '빠큐',
    '빡새끼',
    '뻐규',
    '뻐큐',
    '뻑유',
    '뻑큐',
    '뻨큐',
    '뼈큐',
    'ㅄ',
    'ㅅ1ㅂ',
    'ㅅ1발',
    'ㅅㄲ네',
    'ㅅㅋ네',
    'ㅅㄲ들',
    'ㅅㅋ들',
    '친ㅅㄲ',
    'ㅅ1ㄲ',
    'ㅅㅌㅊ',
    '사까시',
    '사새끼',
    '삼일한',
    '새.끼',
    '새1끼',
    '새1키',
    '새77',
    '새퀴',
    '새킈',
    '색희',
    '색히',
    '샊기',
    '샊히',
    '샹년',
    '섀키',
    '서치해',
    '섬숭이',
    '쉬발',
    '쉬버',
    '쉬이바',
    '쉬펄',
    '슈1발',
    '슈발',
    '슈벌',
    '슈우벌',
    '슈ㅣ발',
    '스벌',
    '싑창',
    '시1발',
    '시미발친',
    '시미친발',
    '시바류',
    '시바알',
    '시발',
    'ㅅㅂ',
    '하남자',
    '하여자',
    '쌉스',
    '썩열',
    '썩렬',
    '쎡열',
    '쎡렬',
    '먹버',
    '대깨',
    '야랄',
    'ㅂㅅ',
    'ㅂ.ㅅ',
    'ㅂ1ㅅ',
    'ㅅ1ㅂ',
    '한남노',
    '한남들',
    '한1남',
    '한남을',
    '싸튀',
    '아줌내',
    '머깨',
    '등신아',
    '개때리',
    '개떄려',
    '염병짓',
    '종간나',
    '간나새끼',
    '쌍간나',
    '좆간나',
    '빠가사리',
    '새기들',
    '애새기',
    '🖕',
    '시방새',
    '시벌탱',
    '시볼탱',
    '시부럴',
    '시부렬',
    '시부울',
    '시뷰럴',
    '시뷰렬',
    '시빨',
    '시새발끼',
    '시이발',
    '시친발미',
    '시팔',
    '시펄',
    '십창',
    '퐁퐁단',
    '십팔',
    'ㅆ1ㄺ',
    'ㅆ1ㅂ',
    'ㅆㄹㄱ',
    'ㅆㄺ',
    'ㅆㅂ',
    '싸물어',
    '쌍년',
    '쌍놈',
    '쌔끼',
    '썅',
    '썌끼',
    '쒸펄',
    '씝창',
    '씨1발',
    '씨@발',
    '씨바라',
    '씨바알',
    '씨발',
    '씨.발',
    '씨방새',
    '씨버럼',
    '씨벌',
    '씨벌탱',
    '씨볼탱',
    '씨부럴',
    '씨부렬',
    '씨뷰럴',
    '씨뷰렬',
    '씨빠빠',
    '씨빨',
    '씨뻘',
    '씨새발끼',
    '씨이발',
    '씨팔',
    '씹귀',
    '씹못',
    '씹뻐럴',
    '씹새끼',
    '씹쌔',
    '씹창',
    '씹치',
    '씹팔',
    '씹할',
    '아닥',
    '애미',
    '앰창',
    '엠뷩신',
    '엠븽신',
    '엠빙신',
    '엠생',
    '엠창',
    '엿같',
    '엿이나',
    '옘병',
    '육변기',
    '입털',
    'ㅈㄹ',
    '전라디언',
    '젼나',
    '젼낰',
    '졀라',
    '졀리',
    '졌같은',
    '능지',
    '조센징',
    '짱깨',
    '짱개',
    '짱꼴라',
    '착짱',
    '죽짱',
    '착1짱',
    '죽1짱',
    '짱골라',
    '좆',
    '좁밥',
    '좇같',
    '죠낸',
    '죠온나',
    '죤나',
    '죤내',
    '죵나',
    '죶',
    '죽어버려',
    '죽여불고',
    '죽여뿌고',
    '줬같은',
    '쥐랄',
    '쥰나',
    '쥰내',
    '쥰니',
    '쥰트',
    '즤랄',
    '지 랄',
    '지1랄',
    '지1뢰',
    '지랄',
    'ezr',
    '2zr',
    '2gr',
    '지롤',
    '찢재',
    '찢1',
    'ㅉ질한',
    '짱께',
    '착짱죽짱',
    '섬숭이',
    '쪽본',
    '쪽1바리',
    '쪽바리',
    '쪽발이',
    '쪽발',
    '쫓같',
    '쬲',
    '찝째끼',
    '창년',
    '창녀',
    '창남',
    '창놈',
    '창넘',
    '凸',
    '쳐받는',
    '취좃',
    '친년',
    '친노마',
    '친놈',
    '친넘',
    'ㅍㅌㅊ',
    'ㅎㅃ',
    'ㅎㅌㅊ',
    '튀기',
    '호로새끼',
    '호로잡',
    '화낭년',
    '화냥년',
    '후1빨',
    '후빨',
    '흑형',
    '흑누나',
    '씹질',
    '털바퀴',
    '털레반',
    '단또',
    '중력500배',
    '캣맘충',
    '개빠',
    '좆냥이',
    '좆스터',
    '존나',
    '좆나',
];
