import { Image, Swiper } from 'antd-mobile';
import { AGENT } from 'constants/Mobile';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { $openBrowser } from 'utils/mobile';

const SwiperItem = React.memo(({ item, onClick }) => (
    <div onClick={onClick}>
        <Image src={item.img_path} />
    </div>
));

export function BannerContainer({ common, dataList }) {
    const navigate = useNavigate();

    const fnMoveToLink = (url) => {
        //const link = 'http://' + url;
        const currentLocation = window.location.href;

        if (
            common.agentType === AGENT.ANDROID_APP ||
            common.agentType === AGENT.IOS_APP
        ) {
            if (url.includes(currentLocation)) {
                const path = `/${url.split(currentLocation)[1]}`;
                navigate(path);
            } else {
                $openBrowser({ link: url });
            }
        } else {
            window.open(url);
        }
    };

    const items = dataList.map((item, key) => (
        <Swiper.Item key={`item_${key}`}>
            <SwiperItem
                item={item}
                onClick={() => fnMoveToLink(item?.img_url)}
            />
        </Swiper.Item>
    ));

    return (
        <section className="ad-area-cph">
            <Swiper autoplay={true} autoplayInterval={3000} loop={true}>
                {items}
            </Swiper>
        </section>
    );
}
