import { combineReducers } from 'redux';

import AuthReducer from 'store/auth-reducer';
import PushReducer from 'store/push-reducer';
import CommonReducer from 'store/common-reducer';
import MainReducer from './main-reducer';
import ListReducer from './list-reducer';
import AlarmReducer from './alarm-reducer';
import HistoryReducer from './history-reducer';
import MoreReducer from './more-reducer';
import PopularReducer from './popular-reducer';

const rootReducers = combineReducers({
    push: PushReducer,
    auth: AuthReducer,
    common: CommonReducer,
    main: MainReducer, // 메인 페이지
    list: ListReducer, // 커뮤니티 리스트
    alarm: AlarmReducer, // 알람
    history: HistoryReducer, // 내 활동내역
    more: MoreReducer, // 더 보기 페이지
    popular: PopularReducer, // 실시간 인기글
});

export default rootReducers;
