import { useEffect, useState } from 'react';
import { BaseButton, BaseInput } from 'components/elements';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import {
    HOWCALL,
    NICKNAME,
    LESSTEN,
    CANUSENICKNAME,
    ALREADYUSENICKNAME,
    JOIN,
    CONGRATULATIONJOIN,
    VALIDNICKNAME,
    NEXT,
    FORBIDDEN_WORD_NICKNAME,
} from 'constants';
import { FORBIDDEN_NICKNAME } from 'constants/ForbiddenNickname';
import { postMemberNicknameAPI } from 'api/member';
import { Loading } from 'components/containers';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { AGENT } from 'constants/Mobile';
import { getForbiddenListAPI } from 'api/community';

export const JoinSettingNick = ({ onMoveStep, userIdx, common }) => {
    const AGENT_TYPE = common.agentType;

    const [originalWindowSize, setOriginalWindowSize] = useState(0);
    const [valid, setValid] = useState(false);
    const [reason, setReason] = useState('');
    const [nicknameText, setNicknameText] = useState('');
    const [isShow, setShow] = useState({
        loading: false,
        showBottom: true,
    });
    const [errorData, setErrorData] = useState(null);
    const { ErrorModal } = useErrorHandler({ errorData });

    // 로딩 토글
    const fnToggleLoading = (value) => {
        setShow((prev) => ({ ...prev, loading: value }));
    };

    const fnHideBottom = () => {
        if (AGENT_TYPE === AGENT.IOS_APP) {
            setShow((prev) => ({ ...prev, showBottom: false }));
        }
    };
    const fnShowBottom = () => {
        if (AGENT_TYPE === AGENT.IOS_APP) {
            setShow((prev) => ({ ...prev, showBottom: true }));
        }
    };

    // 닉네임 유효성 검사
    const fnValidNickname = (nickname) => {
        // 정규 표현식으로 금지어 목록 생성
        const forbiddenArr = new RegExp(FORBIDDEN_NICKNAME.join('|'), 'i');

        //검사
        return forbiddenArr.test(nickname); // 금지어 포함시 true 반환
    };

    const fnValidationCheck = (value) => {
        const check = /^[0-9a-zA-Z가-힣ㆍᆞᆢㄱ-ㅎㅏ-ㅣ\x20]*$/;
        const validNickname = fnValidNickname(value);

        if (value && !check.test(value)) {
            return false;
        }

        if (validNickname) {
            setReason(FORBIDDEN_WORD_NICKNAME);
            setValid(false);
            setNicknameText(value);
            return false;
        }

        if (value?.length > 10 || !value) {
            setReason(VALIDNICKNAME);
            setValid(false);
            setNicknameText(value);
            return false;
        } else {
            setReason(null);
            setValid(true);
            setNicknameText(value);
        }
    };

    const fnSaveNickName = async () => {
        const data = {
            user_idx: userIdx,
            user_nickname: nicknameText,
        };

        fnToggleLoading(true);

        try {
            const res = await postMemberNicknameAPI({ data });
            const { responseCode } = res?.data;

            if (responseCode === 400) {
                setReason(ALREADYUSENICKNAME);
                setValid(false);
                return;
            }

            if (responseCode === 200) onMoveStep();
        } catch (err) {
            console.error(err);
            setErrorData(err);
        } finally {
            fnToggleLoading(false);
        }
    };

    const handleResize = () => {
        if (AGENT_TYPE !== AGENT.ANDROID_APP) return;
        // 안드로이드 대응
        setShow((prevShow) => ({
            ...prevShow,
            showBottom: window.innerHeight === originalWindowSize,
        }));
    };

    useEffect(() => {
        setOriginalWindowSize(window.innerHeight);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [originalWindowSize]);

    return (
        <>
            <BaseLayout header={false} footer={false}>
                <BasePageHeader title={JOIN} pageType={'join'} />
                <div className="wrap wrap-join">
                    <div className="member">
                        <div className="join">
                            <div className="step-2">
                                <h2 className="title-l">
                                    {CONGRATULATIONJOIN}
                                    <br />
                                    {HOWCALL}
                                </h2>
                                <div className="content">
                                    <div className="join-input">
                                        <strong>{NICKNAME}</strong>
                                        <div
                                            className={`${valid ? 'success' : 'fail'}-input`}
                                        >
                                            <BaseInput
                                                placeholder={LESSTEN}
                                                value={nicknameText}
                                                onChange={(value) => {
                                                    fnValidationCheck(value);
                                                }}
                                                maxLength={10}
                                                onBlur={fnShowBottom}
                                                onFocus={fnHideBottom}
                                            />
                                            {valid ? (
                                                <span className="success">
                                                    {CANUSENICKNAME}
                                                </span>
                                            ) : (
                                                <span className="fail">
                                                    {reason}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-wrap">
                        {isShow.showBottom && (
                            <BaseButton
                                label={NEXT}
                                disabled={!valid}
                                onClick={fnSaveNickName}
                            />
                        )}
                    </div>
                </div>

                {/* 에러 */}
                {ErrorModal}
            </BaseLayout>

            {/* 로딩바 */}
            <Loading loading={isShow?.loading} />
        </>
    );
};
