/**
 * @author kkazzong
 * @desc 헤더 액션 영역 - 검색(커뮤니티,수첩) / 알림(공통) / 설정(수첩) / 활동내역(커뮤니티)
 */
import {
    IconAlarm,
    IconAlarmActive,
    IconNote,
    IconSearch,
    IconSetting,
} from 'components/assets/icon';
import {
    URL_DIARY,
    URL_WALLET,
    URL_NOTE_SETTING,
    URL_PUSH_MAIN,
    URL_HOME,
    URL_COMMUNITY,
    URL_COMMUNITY_SEARCH,
    URL_MYHISTORY,
    URL_NOTE_SEARCH,
} from 'routes/RouteUrl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions as pushActions } from 'store/push-reducer';

export function BaseHeaderAction({ pathname, hasAlram }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const fnGoSearch = () => {
        if (pathname === URL_DIARY || pathname === URL_WALLET)
            navigate(`${URL_NOTE_SEARCH}`);
        else if (pathname === URL_COMMUNITY)
            navigate(`${URL_COMMUNITY_SEARCH}`);
    };

    const fnGoNoticeMain = () => {
        // 알람 페이지 접근 시, store 푸쉬 내역 null 로 세팅
        // dispatch(pushActions.postPush({ push: null }));
        navigate(`${URL_PUSH_MAIN}`);
    };

    return (
        <div className={'layout-header-nav-bar diary-wallet-bar'}>
            <div className={'layout-header-nav-bar-box'}>
                {pathname !== URL_HOME && <IconSearch onClick={fnGoSearch} />}
            </div>
            <div className={'layout-header-nav-bar-box'}>
                {hasAlram ? (
                    <IconAlarmActive onClick={fnGoNoticeMain} />
                ) : (
                    <IconAlarm onClick={fnGoNoticeMain} />
                )}
            </div>
            <div className={'layout-header-nav-bar-box'}>
                {(pathname === URL_DIARY || pathname === URL_WALLET) && (
                    <IconSetting
                        onClick={() => {
                            navigate(`${URL_NOTE_SETTING}`);
                        }}
                    />
                )}
                {pathname === URL_COMMUNITY && (
                    <IconNote
                        onClick={() => {
                            navigate(`${URL_MYHISTORY}`);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
