import { Popup } from 'antd-mobile';
import { IconKeyArrowDown, IconKeyArrowUp } from 'components/assets/icon';
import {
    BaseIconButton,
    BaseButton,
    BaseImageButton,
} from 'components/elements/BaseButton';
import { TIME_AM, TIME_PM } from 'constants';
import { useEffect, useState } from 'react';
import { BottomPopUp } from './BottomPopUp';

export const TimeModal = ({ show, defaultData = undefined, onOk, onClose }) => {
    const FLAG = {
        START: 'START',
        END: 'END',
    };

    const AMPM = [TIME_AM, TIME_PM];
    // const [periodIdx, setPeriodIdx] = useState(0);
    // const [hour, setHour] = useState('00');
    // const [min, setMin] = useState('00');

    const [startPeriodIdx, setStartPeriodIdx] = useState(0);
    const [startHour, setStartHour] = useState('00');
    const [startMin, setStartMin] = useState('00');

    const [endPeriodIdx, setEndPeriodIdx] = useState(0);
    const [endHour, setEndHour] = useState('00');
    const [endMin, setEndMin] = useState('00');

    const fnTogglePeriod = (flag) => {
        if (flag === FLAG.START) {
            if (startPeriodIdx === 0) setStartPeriodIdx(1);
            else setStartPeriodIdx(0);
        } else {
            if (endPeriodIdx === 0) setEndPeriodIdx(1);
            else setEndPeriodIdx(0);
        }
    };

    const fnToggleHour = (flag, op) => {
        // 12시간제 (00 ~ 12)
        const hour = flag === FLAG.START ? startHour : endHour;
        let numHour = Number(hour) + Number(op);

        if (numHour === -1) {
            numHour = 12;
            fnTogglePeriod(flag);
        }

        if (numHour === 13) {
            numHour = 0;
            fnTogglePeriod(flag);
        }

        const txtHour = numHour <= 9 ? `0${numHour}` : numHour;
        flag === FLAG.START ? setStartHour(txtHour) : setEndHour(txtHour);
    };

    const fnToggleMin = (flag, op) => {
        // 00 ~ 59
        const min = flag === FLAG.START ? startMin : endMin;
        let numMin = Number(min) + Number(op);

        if (numMin === -1) {
            numMin = 59;
            fnToggleHour(flag, -1);
        }

        if (numMin === 60) {
            numMin = 0;
            fnToggleHour(flag, 1);
        }

        const txtMin = numMin <= 9 ? `0${numMin}` : numMin;
        flag === FLAG.START ? setStartMin(txtMin) : setEndMin(txtMin);
    };

    const fnHandleClose = () => {
        if (onClose) onClose();
    };

    const fnHandleOk = () => {
        const data = {
            [FLAG.START]: {
                periodIdx: startPeriodIdx,
                hour: startHour,
                min: startMin,
            },
            [FLAG.END]: {
                periodIdx: endPeriodIdx,
                hour: endHour,
                min: endMin,
            },
        };

        if (onOk) onOk({ data });
    };
    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    useEffect(() => {
        if (!defaultData) return;
        const st = defaultData?.[FLAG.START];
        const ed = defaultData?.[FLAG.END];

        setStartPeriodIdx(st.periodIdx);
        setStartHour(st.hour);
        setStartMin(st.min);

        setEndPeriodIdx(ed.periodIdx);
        setEndHour(ed.hour);
        setEndMin(ed.min);
    }, [defaultData]);
    return (
        // <Popup
        //     visible={show}
        //     mask={mask}
        //     position={position}
        //     bodyStyle={{
        //       ...bodyStyle,
        //       minHeight: '27rem',
        //     }}
        // >
        <BottomPopUp
            show={show}
            hasBottom={false}
            onMaskClick={fnHandleMaskClick}
        >
            <div className="popup-bottom">
                <div className="popup-wrap time-modal">
                    <div className="popup-head popup-close">
                        <strong>시간 선택</strong>
                        <BaseImageButton
                            imageSrc={'/assets/images/icon-close.svg'}
                            altText={'닫기'}
                            onClick={fnHandleClose}
                        />
                    </div>
                    <div className="popup-content">
                        <strong className="txt-center mt-24 mb-24">
                            시작 시간
                        </strong>
                        <div className="bell-box">
                            <div className="time">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnTogglePeriod(FLAG.START)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{AMPM[startPeriodIdx]}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnTogglePeriod(FLAG.START)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                            <div className="hour">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnToggleHour(FLAG.START, 1)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{startHour}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnToggleHour(FLAG.START, -1)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                            <div className="minute">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnToggleMin(FLAG.START, 1)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{startMin}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnToggleMin(FLAG.START, -1)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                        </div>
                        <div className="divider-16 mt-24 mb-24"></div>
                        <strong className="txt-center mt-24 mb-24">
                            종료 시간
                        </strong>
                        <div className="bell-box mb-24">
                            <div className="time">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnTogglePeriod(FLAG.END)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{AMPM[endPeriodIdx]}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnTogglePeriod(FLAG.END)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                            <div className="hour">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnToggleHour(FLAG.END, 1)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{endHour}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnToggleHour(FLAG.END, -1)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                            <div className="minute">
                                <BaseIconButton
                                    className="up"
                                    onClick={() => fnToggleMin(FLAG.END, 1)}
                                >
                                    <IconKeyArrowUp />
                                </BaseIconButton>
                                <p>{endMin}</p>
                                <BaseIconButton
                                    className="down"
                                    onClick={() => fnToggleMin(FLAG.END, -1)}
                                >
                                    <IconKeyArrowDown />
                                </BaseIconButton>
                            </div>
                        </div>
                    </div>
                    <div className="popup-button-wrap">
                        <BaseButton label={'확인'} onClick={fnHandleOk} />
                    </div>
                </div>
            </div>
        </BottomPopUp>
    );
};
