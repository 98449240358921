import { useState } from 'react';
import { postInterestAnimalAPI } from 'api/member';
import { BaseButton } from 'components/elements';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import {
    PLEASESELECT,
    ANIMALSTORYSEECOMMUNITY,
    NOTSELECTBUTCANSEE,
    CANSELECTANIMAL,
    INTERESTANIMAL,
    JOIN,
    NEXT,
} from 'constants';
import { AnimalSelectBox, Loading } from 'components/containers';
import { useErrorHandler } from 'hooks/useErrorHandler';

export const JoinSettingAnimal = ({ onMoveStep, userIdx }) => {
    const [choices, setChoices] = useState([]);
    const [isShow, setShow] = useState({
        loading: false,
    });
    const [errorData, setErrorData] = useState(null);
    const { ErrorModal } = useErrorHandler({ errorData });

    // 로딩 토글
    const fnToggleLoading = (value) => {
        setShow((prev) => ({ ...prev, loading: value }));
    };

    const fnSetSelected = (codeArr) => {
        setChoices(codeArr);
    };

    const fnPostSelectedAnimal = async () => {
        const data = {
            user_idx: userIdx,
            animal_code: choices,
        };

        fnToggleLoading(true);

        try {
            const res = await postInterestAnimalAPI({ data });
            const { responseCode } = res?.data;

            if (responseCode === 200) onMoveStep();
        } catch (err) {
            console.error(err);
            setErrorData(err);
        } finally {
            fnToggleLoading(false);
        }
    };

    return (
        <>
            <BaseLayout header={false} footer={false}>
                <BasePageHeader title={JOIN} pageType={'join'} />
                <div className="wrap wrap-join join-setting-animal">
                    <div className="member">
                        <div className="join">
                            <div className="step-3">
                                <h2 className="title-l">
                                    {INTERESTANIMAL}
                                    <br />
                                    {PLEASESELECT}
                                </h2>
                                <div className="reference">
                                    <p className="reference-s">
                                        {CANSELECTANIMAL}
                                        <br />
                                        {ANIMALSTORYSEECOMMUNITY}
                                    </p>
                                </div>
                                <div className="content">
                                    <AnimalSelectBox onChoice={fnSetSelected} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-wrap">
                        <div className="reference">
                            <p className="reference-xs">{NOTSELECTBUTCANSEE}</p>
                        </div>
                        <BaseButton
                            label={NEXT}
                            onClick={fnPostSelectedAnimal}
                            disabled={choices.length > 0 ? false : true}
                        />
                    </div>
                </div>

                {/* 에러 */}
                {ErrorModal}
            </BaseLayout>

            {/* 로딩바 */}
            <Loading loading={isShow?.loading} />
        </>
    );
};
