import { DAYS_LABEL } from 'constants/Label';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const HandleWrapper = styled.div`
    height: 0.6rem;
    border-radius: 0 0 1.2rem 1.2rem;
    position: relative;
    padding: 0.4rem 0 0.4rem 0;
    // background: red;
`;

const Handle = styled.div`
    width: 4rem;
    height: 0.4rem;
    border-radius: 0.2rem;
    background-color: #dee2e6;
    margin: auto;
`;

/**
 * 한 줄 달력
 * @param defaultDate : YYYYMMDD
 * @returns
 */
export function LineCalendar({ defaultDate = '', onChange }) {
    const [today] = useState(dayjs().format('YYYYMMDD'));
    const [selectedDate, setSelectedDate] = useState(
        defaultDate || dayjs().format('YYYYMMDD'),
    );
    const [dates, setDates] = useState([]);

    const ref = useRef(null);

    const getDates = () => {
        // const selectedYearMonth = selectedDate.substring(0, 6);

        // // 현재 달 마지막 날
        // const lastDate = moment(selectedDate).clone().daysInMonth();

        // const datesStack = []; // YYYYMMDD
        // for (let i = 1; i < lastDate + 1; i++) {
        //   if (i < 10) {
        //     datesStack.push(`${selectedYearMonth}0${i}`);
        //   } else {
        //     datesStack.push(`${selectedYearMonth}${i}`);
        //   }
        //   setDates(datesStack);
        // }

        // 이번 주 첫째 날
        const firstDate = dayjs(defaultDate).startOf('week').day(0); // 0 (요일 - 일요일 : 0, 토요일 : 6)

        const datesStack = []; // YYYYMMDD

        let curDate = firstDate;

        for (let i = 0; i < 7; i++) {
            datesStack.push(curDate.format('YYYYMMDD'));
            curDate = curDate.add(1, 'days');
        }
        setDates(datesStack);
    };

    const onCBChange = (selectedItem) => {
        setSelectedDate(selectedItem);
        if (onChange) onChange(selectedItem);
    };

    useEffect(() => {
        getDates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDate]);

    // useEffect(() => {
    //   console.log("width", ref.current ? ref.current.offsetWidth : 0);
    // }, [ref.current]);

    return (
        <div className="cmp-line-calender-day-box">
            <div className={'cmp-line-calendar-day-wrap'}>
                <div className={'cmp-line-calendar-day-label'}>
                    {DAYS_LABEL.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className={
                                    'cmp-line-calendar-day-value caption'
                                }
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={'cmp-line-calendar-date-wrap'} ref={ref}>
                <div className={'cmp-line-calendar-date-label'}>
                    {dates.map((item) => {
                        let circleStyle = '';
                        if (item === selectedDate) {
                            circleStyle =
                                'cmp-line-calendar-date-value-circle-selected';
                        } else if (item === today) {
                            circleStyle =
                                'cmp-line-calendar-date-value-circle-today';
                        }
                        return (
                            <div
                                key={item}
                                className={'cmp-line-calendar-date-value'}
                                onClick={() => onCBChange(item)}
                            >
                                <div
                                    className={`cmp-line-calendar-date-value-circle ${circleStyle}`}
                                >
                                    {Number(item.substr(-2))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <HandleWrapper>
                <Handle />
            </HandleWrapper>
        </div>
    );
}
