import React, { useEffect, useState } from 'react';
import { DAYS_LABEL } from 'constants/Label';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

/**
 * 요일
 * @returns
 */
function DayCell({ value }) {
    return (
        <div className="cmp-simple-calendar-day-wrap">
            <div className="cmp-simple-calendar-day-label">{value}</div>
        </div>
    );
}
/**
 * 일
 * @returns
 */
function DateCell({ today, selectedDay, value, onChange }) {
    const thisMonth = selectedDay ? selectedDay.slice(0, 6) : today.slice(0, 6);
    const month = value.slice(0, 6);
    const day = Number(value.slice(-2));

    //console.log(`thisMonth: ${thisMonth} month: ${month}`);

    let dateStyle = {};

    if (thisMonth !== month) {
        dateStyle = { ...dateStyle, color: 'var(--color-grayb-300)' };
    }

    if (value === today) {
        dateStyle = {
            ...dateStyle,
            color: 'var(--color-today-date)',
            backgroundColor: 'var(--bgcolor-today-date)',
        };
    }
    if (value === selectedDay) {
        dateStyle = {
            ...dateStyle,
            color: '#ffffff',
            fontWeight: 'bold',
            backgroundColor: 'var(--color-main-500)',
        };
    }

    return (
        <div
            className="cmp-simple-calendar-date-wrap"
            onClick={() => onChange(value)}
        >
            <div
                className="cmp-simple-calendar-date-label"
                style={{ ...dateStyle }}
            >
                {day}
            </div>
        </div>
    );
}

export function Calendar({ defaultDate = '', onChange }) {
    const [today] = useState(dayjs().format('YYYYMMDD'));
    const [selectedDay, setSelectedDay] = useState(defaultDate || '');
    const [dates, setDates] = useState([]);

    const getDates = () => {
        // 현재 달 첫째 날
        // 선택된 경우 선택할 날짜 기준으로 시작일 설정
        const firstDate = dayjs(defaultDate ? defaultDate : today)
            .startOf('month')
            .day(0); // 0 (요일 - 일요일 : 0, 토요일: 6 )

        // 현재 달 마지막 날
        // const lastDate = moment(today).clone().endOf("month").day(6);

        const dateStack = [];

        let curDate = firstDate;

        // 6 week로
        for (let i = 0; i < 42; i++) {
            dateStack.push(curDate.format('YYYYMMDD'));
            curDate = curDate.add(1, 'day');
        }

        // console.log(`firstDate : ${firstDate}`);
        // console.log(`lastDate : ${lastDate}`);
        // console.log(`dateStack : `, dateStack);

        setDates(dateStack);
    };

    const onChangeDate = (value) => {
        setSelectedDay(value);
        if (onChange) onChange(value);
    };

    useEffect(() => {
        getDates();
    }, []);

    useEffect(() => {
        // 선택하지 않고 달력 월만 옮겼을 경우에도 이벤트 발생 필요
        onChangeDate(defaultDate);
    }, [defaultDate]);
    return (
        <>
            <div className="cmp-simple-calendar-week">
                {DAYS_LABEL?.map((value) => {
                    return <DayCell key={value} value={value} />;
                })}
            </div>
            <div className="cmp-simple-calendar-week">
                {dates?.map((value) => {
                    return (
                        <DateCell
                            key={value}
                            today={today}
                            selectedDay={selectedDay}
                            value={value}
                            onChange={onChangeDate}
                        />
                    );
                })}
            </div>
        </>
    );
}
