import { CenterPopup } from 'antd-mobile';
import { useEffect } from 'react';

export function AlertModal({
    show,
    label = '',
    subtitle = '',
    footer,
    children,
    onOk,
    onClose,
}) {
    const fnHandleMaskClick = () => {
        if (onClose) onClose();
    };

    const preventScroll = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        if (show) {
            window.addEventListener('wheel', preventScroll, { passive: false });
            window.addEventListener('touchmove', preventScroll, {
                passive: false,
            });
        } else {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        }

        return () => {
            window.removeEventListener('wheel', preventScroll);
            window.removeEventListener('touchmove', preventScroll);
        };
    }, [show]);

    return (
        <CenterPopup visible={show} onMaskClick={fnHandleMaskClick}>
            <div className="popup-list alert-modal">
                <div className="popup-wrap4">
                    <div className="popup-title">
                        <strong className="popup-title1">{label}</strong>
                        <div className="reference">
                            <p className="reference-s2">{subtitle}</p>
                        </div>
                    </div>
                    {children && (
                        <div className="popup-content">{children}</div>
                    )}
                    <div className="popup-center-footer">{footer}</div>
                </div>
            </div>
        </CenterPopup>
    );
}
