import { ProfileImage } from 'components/containers';
import { useDiaryWalllet } from 'hooks/useDiaryWallet';
import { ellipseStr, getAmountWithCommaDelim } from 'utils/utils';

/*
오늘의 수첩 - 가계부
검색 (메인 only) - 가계부
*/
export const TodayWalletListItem = ({ dataList, onClick }) => {
    const fnHandleClick = (date) => {
        if (onClick) onClick(date);
    };

    return dataList.map((item, idx) => (
        <li
            key={`todayw_idx_${idx}`}
            className="profile-horizental profile-img-20"
            onClick={() => fnHandleClick(item.diary_date)}
        >
            <div className="profile-wrap profile-img-20">
                <ProfileImage size={20} imageSrc={item.pet_profile_img_path} />
            </div>
            <div className="label-l">{item.category_name}</div>
            <span className="account-con">{ellipseStr(item.content, 8)}</span>
            <span className="account-price">{`${getAmountWithCommaDelim(item.amount)}원`}</span>
        </li>
    ));
};
