import { $permission, $permissionIOS, $requestPermission } from 'utils/mobile';

/**
 *
 * 앱 접근 권한 확인
 * (카메라, GPS 등)
 * accessType은 AccessPermissionPopup 참고
 */
export const useAccessPermission = () => {
    // 앱 접근 권한 확인
    const checkAccess = ({ type }) => {
        const permission = $permission({ type });
        // // if (permission) {
        // //     // 접근권한 있는 경우
        // //     //fnOpenChoiceModal()
        // // } else {
        // //     // 접근권한 없는 경우
        // //     //fnOpenAccessModal()
        // // }
        return permission;
    };

    const checkAccessIOS = async ({ type }) => {
        // alert('1 checkAccess IOS')
        try {
            return await $permissionIOS({ type });
        } catch (err) {
            throw err;
        }
    };

    // 앱 접근 권한 요청
    const requestAccess = ({ type }) => {
        const requestPermission = $requestPermission({ type });
        return requestPermission;
    };

    return { checkAccess, checkAccessIOS, requestAccess };
};
