export const HandBook = () => {
    return (
        <div className="handbook">
            <div className="hand-off">
                <div className="handbook-wrap">
                    <div className="title">
                        <strong>오늘의 수첩</strong>
                        <button type="button" className="handbook-btn">
                            <img
                                src="/assets/images/arrow-top.svg"
                                alt="열기"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
