import { BaseLayout, BasePageHeader } from 'components/layouts';
import { AGREEMARKETING } from 'constants';

export const JoinTermMarketing = ({ onMoveStepBack, onMoveStep }) => {
    return (
        <BaseLayout header={false} footer={false}>
            <>
                <main>
                    <BasePageHeader title={AGREEMARKETING} />
                    <div className="wrap setting-service">
                        <div className="wrap setting-service">
                            <h2 className="terms-title">
                                마케팅 활용정보 제공에 관한 동의서
                            </h2>
                            <div className="terms-content">
                                <p className="sentence-title">제1조(목적)</p>
                                <p className="sentence">
                                    컴펫홈(이하 ‘회사’라 한다)의 컴펫홈에 관한
                                    모든 관련 서비스(이하 ‘서비스’라 한다)는
                                    고객사에게 보다 다양한 정보를 제공하고,
                                    서비스의 질을 향상시키기 위하여 당사의
                                    제휴사에게 「개인정보보호법」, 「정보통신망
                                    이용촉진 및 정보보호 등에 관한 법률 」및
                                    「신용정보의 이용 및 보호에 관한 법률」의
                                    규정을 준수하여 고객사의 개인(신용)정보를
                                    회사 혹은 제3자에게 상품소개 및 홍보 등
                                    영업목적으로 제공할 수 있습니다.
                                </p>

                                <p className="sentence-title">
                                    제2조 (수집 및 활용 관련 정보)
                                </p>
                                <p className="sentence">
                                    개인정보가 제공되는 비즈니스 파트너사, 제공
                                    정보, 제공된 정보의 이용목적은 아래와
                                    같습니다. 고객사들 중 이 개인정보의 회사
                                    혹은 제3자 마케팅 활용동의서 등에 동의하신
                                    고객사들의 정보만이 제공되며, 제공된 정보는
                                    명시된 이용목적을 벗어나 이용되지 않고,
                                    개인정보의 유출 등 사고가 일어나지 않도록
                                    더욱 철저한 보안이 이루어지도록 노력하고
                                    있습니다.
                                </p>

                                <p className="sentence-title">
                                    제3조 (개인 정보의 제3자 제공)
                                </p>
                                <p className="sentence">
                                    '컴펫홈'은 이용자의 개인정보를 '개인정보
                                    수집항목' 및 '이용목적'에서 고지한 범위
                                    내에서 사용하며, 동 범위를 초과하여
                                    이용하거나 타인 또는 타기업, 기관에 제공하지
                                    않습니다. 단, 다음의 경우는 예외로 합니다. 
                                    <div className="indent">
                                        <p className="number item01">
                                            관계법령에 의하여 수사상의 목적으로
                                            관계기관으로부터의 요구가 있을 경우{' '}
                                        </p>
                                        <p className="number item02">
                                            통계작성, 학술연구나 시장조사를
                                            위하여 특정 개인을 식별할 수 없는
                                            형태로 제공하는 경우{' '}
                                        </p>
                                        <p className="number item03">
                                            이용자로부터 별도의 동의를 받은 경우{' '}
                                        </p>
                                    </div>
                                </p>

                                <p className="sentence-title">
                                    제4조 (제3자 정보제공의 동의 철회)
                                </p>
                                <p className="sentence">
                                    본 동의서에 동의하고 가입하신 신규회원 중
                                    제3자 정보제공을 철회하고 싶은 회원은 이미
                                    제3자에게 제공된 개인정보라 하더라도,
                                    언제든지 열람, 정정, 삭제를 요구할 수
                                    있습니다. <br />
                                    열람, 정정, 삭제 및 정보제공 동의 철회는
                                    컴펫홈(
                                    <a href="tel:+827088800307">
                                        070-8880-0307
                                    </a>{' '}
                                    혹은{' '}
                                    <a href="mailto:come.pet.home@mcbglobal.pro">
                                        come.pet.home@mcbglobal.pro
                                    </a>
                                    )을 통해 본인확인 후 요청할 수 있습니다.
                                    해당 요청의 근거자료를 남기기 위해 해당
                                    통화내역은 녹취될 수 있으며 회원은 이에
                                    동의하는 것으로 간주합니다. <br />
                                    이미 제공된 회원정보를 철회하는 데는 최대
                                    10일의 기간이 소요됩니다. 활용동의 철회를
                                    요청하시더라도 위와 같은 이유로 해당 철회
                                    적용 이전까지 마케팅에 활용될 수 있음을
                                    알려드립니다. 회사는 고객사의 소중한 정보를
                                    보호하기 위하여 신속하게 처리되도록 최선의
                                    노력을 다하겠습니다.
                                </p>

                                <p className="sentence-title">시행 및 개정</p>
                                <p className="sentence">
                                    (최초시행일) 본 약관은 2024년 7월 1일부터
                                    시행합니다.
                                </p>
                            </div>
                            <div className="button-wrap">
                                {/* <button className="disabled" type="button" >다음</button>  */}
                            </div>
                        </div>
                        <div className="button-wrap">
                            {/* <button className="disabled" type="button" >다음</button>  */}
                        </div>
                    </div>
                </main>
            </>
        </BaseLayout>
    );
};
