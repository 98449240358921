import client from '../axios';
import {
    GET_SHARE_CODE,
    POST_ASSET,
    POST_BUDGET,
    POST_PET,
    POST_SHARE_CODE,
    PUT_PET,
    POST_WALLET_CATEGORY,
    PUT_WALLET_CATEGORY,
    GET_RECOMMENT_CODE,
    GET_LOGOUT,
    GET_WITHDRAW_REASON,
    POST_WITHDRAW,
    GET_UNSUBSCRIBE_REASON,
    POST_UNSUBSCRIBE,
    POST_ALARM_NOTIFICATION,
    POST_ALARM_EVENT,
    POST_ALARM_MISSING,
    POST_ALARM_GOOD,
    POST_ALARM_COMMENT,
    POST_ALARM_NEIGHBORS,
    POST_ALARM_DIARY,
    POST_ALARM_WALLET,
    POST_ALARM_STAT,
    POST_ALARM_DIARY_CHANGED,
    POST_ALARM_WALLET_CHANGED,
    POST_ALARM_DISTURB,
    GET_PUSH_INFO,
    GET_SHARE_MEMBER_LIST,
    DELETE_SHARE_MEMBER,
    PUT_ASSET,
    POST_COUPON,
    GET_SUBSCRIBE,
    GET_BACKUP_DIARY,
    GET_RESTORE_DIARY,
    GET_INIT_DIARY,
} from './endpoint';

export const postPetApi = ({ data }) => {
    return client.post(POST_PET, data);
};
export const putPetApi = ({ data }) => {
    return client.put(PUT_PET, data);
};
export const getShareCodeApi = ({ params }) => {
    return client.get(GET_SHARE_CODE, { params });
};
export const getShareMemberListApi = ({ params }) => {
    return client.get(GET_SHARE_MEMBER_LIST, { params });
};
export const deleteShareApi = ({ idx, params }) => {
    return client.delete(DELETE_SHARE_MEMBER.replace(':user_idx', idx), {
        params,
    });
};
export const postShareCodeApi = ({ data }) => {
    return client.post(POST_SHARE_CODE, data);
};
export const postBudget = ({ data }) => {
    return client.post(POST_BUDGET, data);
};
export const postAsset = ({ data }) => {
    return client.post(POST_ASSET, data);
};
export const putAsset = ({ data }) => {
    return client.put(PUT_ASSET, data);
};
export const postWalletCategory = ({ data }) => {
    return client.post(POST_WALLET_CATEGORY, data);
};
export const putWalletCategory = ({ data }) => {
    return client.put(PUT_WALLET_CATEGORY, data);
};
export const getBackupDiaryApi = ({ params }) => {
    return client.get(GET_BACKUP_DIARY, { params });
};
export const getRestoreDiaryApi = ({ params }) => {
    return client.get(GET_RESTORE_DIARY, { params });
};
export const getInitDiaryApi = ({ params }) => {
    return client.get(GET_INIT_DIARY, { params });
};

// more
export const getRecommendCodeApi = ({ params }) => {
    return client.get(GET_RECOMMENT_CODE, { params });
};
export const logoutApi = ({ params }) => {
    return client.get(GET_LOGOUT, { params });
};
export const getWithdrawReasonApi = () => {
    return client.get(GET_WITHDRAW_REASON);
};
export const postWithdraw = ({ data }) => {
    return client.post(POST_WITHDRAW, data);
};
export const getSubscribeApi = ({ params }) => {
    return client.get(GET_SUBSCRIBE, { params });
};
export const getUnsubscribeReasonApi = () => {
    return client.get(GET_UNSUBSCRIBE_REASON);
};
export const postUnsubscribe = ({ data }) => {
    return client.post(POST_UNSUBSCRIBE, data);
};
export const getPushInfoApi = ({ params }) => {
    return client.get(GET_PUSH_INFO, { params });
};
export const postNotification = ({ type, data }) => {
    let url;
    if (type === 'NOTIFICATION') url = POST_ALARM_NOTIFICATION;
    else if (type === 'EVENT') url = POST_ALARM_EVENT;
    else if (type === 'MISSING') url = POST_ALARM_MISSING;
    else if (type === 'GOOD') url = POST_ALARM_GOOD;
    else if (type === 'COMMENT') url = POST_ALARM_COMMENT;
    else if (type === 'NEIGHBORS') url = POST_ALARM_NEIGHBORS;
    else if (type === 'DIARY') url = POST_ALARM_DIARY;
    else if (type === 'WALLET') url = POST_ALARM_WALLET;
    else if (type === 'STAT') url = POST_ALARM_STAT;
    else if (type === 'DIARY_CHANGED') url = POST_ALARM_DIARY_CHANGED;
    else if (type === 'WALLET_CHANGED') url = POST_ALARM_WALLET_CHANGED;
    return client.post(url, data);
};
export const postDisturb = ({ data }) => {
    return client.post(POST_ALARM_DISTURB, data);
};
export const postCoupon = ({ data }) => {
    return client.post(POST_COUPON, data);
};
