import { useLocalStorage } from 'hooks/useLocalStorage';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actions as commonActions } from 'store/common-reducer';
import { isMobile } from 'utils/mobile';
import { getToday } from 'utils/utils';

export const MobileInterface = () => {
    const dispatch = useDispatch();

    const [mobileLocation, setMobileLocation] = useLocalStorage(
        'persist:location',
        null,
    );
    const once = useRef(false);

    useEffect(() => {
        window.NativeInterface = {
            currentLocation: (latitude, longitude) => {
                // alert('[모바일->currentLocation] 위치:'+latitude+"::::"+longitude);
                if (isMobile.Android()) {
                    dispatch(
                        commonActions.postLocation({ latitude, longitude }),
                    );
                    setMobileLocation({ latitude, longitude });
                } else if (isMobile.iOS()) {
                    // dispatch(commonActions.postLocation({ latitude, longitude }))
                    if (once.current === false) {
                        // ios에서는 계속 호출함 한번만 호출하도록
                        dispatch(
                            commonActions.postLocation({ latitude, longitude }),
                        );
                        setMobileLocation({ latitude, longitude });
                    }
                    once.current = true;
                }
            },
            permissionCallback: (type, result) => {
                // alert('[모바일->permissionCallback]'+type+" "+result)
                dispatch(
                    commonActions.postPermission({
                        type: Number(type),
                        result,
                    }),
                );
            },
            uploadException: (type) => {
                // 400: 업로드 최대갯수 초과
                dispatch(commonActions.postFileException({ type }));
            },
            uploadComplete: (filePath, fileListIdx) => {
                // alert('[모바일->uploadComplete]'+fileListIdx+" "+filePath)
                dispatch(
                    commonActions.postFile({
                        filePath,
                        fileListIdx: Number(fileListIdx),
                    }),
                );
            },
            purchaseComplete: (token) => {
                // 구매완료 후 토큰 전송
                // alert('[모바일->purchaseComplete]'+token);
                // 결제시간 - 현재시간
                const today = getToday('YYYYMMDDHHMMSS', '-', ':');
                dispatch(
                    commonActions.postPurchaseToken({
                        token,
                        completeDate: today,
                    }),
                );
            },
        };

        return () => {
            dispatch(commonActions.initFile());
            dispatch(commonActions.initFileException());
            dispatch(commonActions.initPurchaseToken());
            dispatch(commonActions.initPermission());
        };
    }, []);

    return <></>;
};
